<template>
    <div>
        <ErrorPage :info="info" />
    </div>
</template>

<script>
import ErrorPage from "@/components/ErrorPage.vue"
export default {
    name: "Unauthorized",
    components: { ErrorPage },
    data: () => ({
        info: {
            status: "401",
            title: "คุณไม่มีสิทธิเข้าถึงหน้าเว็บไซท์นี้",
            subtitle: "กรุณาล๊อกอินเพื่อเข้าสู่ระบบใหม่อีกครั้ง",
            btn: "เข้าสู่ระบบ",
        },
    }),
}
</script>
