<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="6">
                    <h3>
                        <v-icon class="mr-4">mdi-text-box-outline</v-icon>
                        {{ $t("reports.title") }} > Glasshouse-income
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="right">
                    เรียงลำดับข้อมูล
                    <v-btn class="primary" @click="name_asc"
                        >ชื่อโรงเรือน</v-btn
                    >
                    <v-btn class="primary" @click="income_asc"
                        >รายได้ของโรงเรือน น้อย-มาก</v-btn
                    >
                    <v-btn class="primary" @click="income_desc"
                        >รายได้ของโรงเรือน มาก-น้อย</v-btn
                    >
                </v-col>
                <v-col cols="12">
                    <v-container fluid>
                        <TransitionGroup tag="ul" name="fade" class="row">
                            <v-col
                                cols="3"
                                v-for="farm in farms"
                                :key="farm.farm_id"
                            >
                                <IncomeGlasshouse :farm="farm" />
                            </v-col>
                        </TransitionGroup>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import IncomeGlasshouse from "@/components/IncomeGlasshouse.vue"
export default {
    name: "GlasshouseIncome",
    components: { IncomeGlasshouse },
    mounted() {},
    data: () => ({
        farms: [
            {
                farm_id: 1,
                farm_name: "โรงเรือนที่ 02",
                income: 1500,
            },
            {
                farm_id: 2,
                farm_name: "โรงเรือนที่ 01",
                income: 100,
            },
            {
                farm_id: 3,
                farm_name: "โรงเรือนที่ 03",
                income: 1800,
            },
            {
                farm_id: 4,
                farm_name: "โรงเรือนที่ 04",
                income: 0,
            },
        ],
    }),
    methods: {
        name_asc() {
            this.farms = this.farms.sort((a, b) => {
                return a.farm_name < b.farm_name ? -1 : 1
            })
        },
        income_asc() {
            this.farms = this.farms.sort((a, b) => {
                return a.income < b.income ? -1 : 1
            })
        },
        income_desc() {
            this.farms = this.farms.sort((a, b) => {
                return a.income > b.income ? -1 : 1
            })
        },
        // asc() {
        //     this.farms = this.farms.sort((a, b) => {
        //         return a.farm_id < b.farm_id ? -1 : 1
        //     })
        // },
        // desc() {
        //     this.farms = this.farms.sort((a, b) => {
        //         return a.farm_id > b.farm_id ? -1 : 1
        //     })
        // },
    },
    beforeCreate() {},
}
</script>

<style scoped>
.row {
    position: relative;
    padding: 0;
}

.item {
    width: 100%;
    height: 30px;
    background-color: #f3f3f3;
    border: 1px solid #666;
    box-sizing: border-box;
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    position: absolute;
}
</style>
