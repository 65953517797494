<template>
    <div>
        <v-row class="pa-0 ma-0 d-none d-md-flex" justify="center" no-gutters>
            <v-col cols="4" class="pa-0 ma-0">
                <v-lottie-player
                    width="100%"
                    height="400px"
                    loop
                    name="notfound"
                    :animationData="require('@/assets/lottie/error-trans.json')"
                />
            </v-col>
            <v-col cols="4" class="pa-0 ma-0" justify="center">
                <p class="text-h1 font-weight-medium">
                    {{ info.status }} ERROR
                </p>
                <p class="text-h4">{{ info.title }}</p>
                <p class="text-h5 mt-10">{{ info.subtitle }}</p>
                <v-btn
                    center
                    class="mt-10"
                    rounded
                    color="white--text green darken-3"
                    @click="reload()"
                >
                    {{ info.btn }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row
            class="pa-0 ma-0 d-sm-flex d-md-none"
            justify="center"
            no-gutters
        >
            <v-col cols="12" class="pa-0 ma-0" align="center">
                <v-lottie-player
                    height="200px"
                    loop
                    name="notfound"
                    :animationData="require('@/assets/lottie/error-trans.json')"
                />
            </v-col>
            <v-col cols="12" class="px-5 ma-0" justify="center">
                <p class="text-h6">{{ info.title }}</p>
                <p class="text-subtitle-2 mt-3">{{ info.subtitle }}</p>
            </v-col>
            <v-col cols="12" class="px-5 ma-0" justify="center" align="center">
                <v-btn
                    center
                    class="mt-3"
                    rounded
                    color="white--text green darken-3"
                    @click="reload()"
                >
                    {{ info.btn }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "ErrorPage",
    props: ["info"],
    components: {},
    mounted() {
        setTimeout(() => {
            this.reload()
        }, 5000)
    },
    data: () => ({
        link: "",
    }),
    methods: {
        async reload() {
            if (this.info.status !== "401") {
                window.location.href = "/Farm"
            } else {
                localStorage.token_smf = ""
                localStorage.user_lang = "en"
                localStorage.user_role = ""
                this.$store.commit("setLogInStatus", false)
                window.location.href = "/"
            }
        },
    },
}
</script>
