<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        width="7"
        size="64"
        color="light-green"></v-progress-circular>
    </v-overlay>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="5" class="pr-0 mr-0">
          <v-container fluid class="px-0">
            <v-card color="white" elevation="0" rounded="lg">
              <v-card-text>
                <v-container fluid class="px-0">
                  <v-row>
                    <v-col
                      cols="10"
                      md="6"
                      class="offset-1 elevation-5 green darken-3 pt-4 mb-3 mt-n8 rounded-lg white--text">
                      <h3 class="font-weight-regular">
                        พืชที่ปลูก :
                        {{ detail.farms.plant_name }}
                      </h3>
                      <h3 class="font-weight-medium">
                        วันที่เริ่มปลูก :
                        {{ detail.farms.start_date }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row class="d-sm-flex d-md-none">
                    <v-col cols="4" class="pa-0 pr-1">
                      <v-list
                        rounded
                        :class="
                          'h-100 ' +
                          (!disabled ? 'bg-temp ' : 'grey lighten-3 ')
                        ">
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-icon class="px-0 mx-0">
                            <v-icon large v-text="'mdi-thermometer'"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content align="right">
                            <v-list-item-title>
                              <h2>
                                {{ parseInt(detail.farms.temperature) }}
                                <span :style="'font-size:55%'">&deg;C</span>
                              </h2>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-content class="pa-0 ma-0" align="center">
                            <v-list-item-subtitle
                              class="text-wrap text-caption">
                              {{ $t("sys.temp") }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="4" class="pa-0 pr-1">
                      <v-list
                        rounded
                        :class="
                          'h-100 ' +
                          (!disabled ? 'bg-humid ' : 'grey lighten-3 ')
                        ">
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-icon class="px-0 mx-0">
                            <v-icon large v-text="'mdi-water'"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content align="right">
                            <v-list-item-title>
                              <h2>
                                {{ parseInt(detail.farms.humidity) }}
                                <span :style="'font-size:55%'">%</span>
                              </h2>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-content class="pa-0 ma-0" align="center">
                            <v-list-item-subtitle
                              class="text-wrap text-caption">
                              {{ $t("sys.humid") }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="4" class="pa-0 pr-1">
                      <v-list
                        rounded
                        :class="
                          'h-100 ' +
                          (!disabled ? 'bg-soil-moist ' : 'grey lighten-3 ')
                        ">
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-icon class="px-0 mx-0">
                            <v-icon large v-text="'mdi-water'"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content align="right">
                            <v-list-item-title>
                              <h2>
                                {{ parseInt(detail.farms.soil_moisture) }}
                                <span :style="'font-size:55%'">%</span>
                              </h2>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-content class="pa-0 ma-0" align="center">
                            <v-list-item-subtitle
                              class="text-wrap text-caption">
                              {{ $t("sys.moist") }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                  <v-row class="d-none d-md-flex">
                    <v-col cols="12" class="pt-8">
                      <img width="60%" src="../assets/glasshouse.svg" />
                      <span class="text-h4 top-right-temptext"
                        >{{ parseInt(detail.farms.temperature) }}&deg;C
                      </span>
                      <vue-thermometer
                        class="top-right-temp"
                        :options="tempChartOptions"
                        :value="detail.farms.temperature"
                        :min="-0"
                        :max="50" />
                      <gauge
                        class="top-right-humid"
                        heading="ความชื้นในอากาศ"
                        :min="0"
                        :max="100"
                        :headingLevel="4"
                        fontSize="1.5em"
                        unit="%"
                        activeFill="#DE6E4B"
                        inactiveFill="#ededed"
                        :value="detail.farms.humidity" />
                      <gauge
                        class="top-right-soilm"
                        heading="ความชื้นในดิน"
                        :min="0"
                        :max="100"
                        :headingLevel="4"
                        fontSize="1.5em"
                        unit="%"
                        activeFill="#7A6563"
                        inactiveFill="#ededed"
                        :value="detail.farms.soil_moisture" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-container fluid class="px-0 mt-7">
                      <v-row align="center">
                        <v-col cols="12" v-if="disabled">
                          <v-alert text dense type="warning">
                            ข้อมูลจาก Node Sensor หายไป กรุณาตรวจสอบอุปกรณ์
                          </v-alert>
                        </v-col>
                        <v-col cols="6">
                          <h3>ตั้งค่าระบบการทำงานอัตโนมัติ</h3>
                        </v-col>
                        <v-col cols="6" align="right">
                          <v-btn
                            v-if="userRole !== 'guest'"
                            class="mr-3"
                            @click="dialog_new_condition = true"
                            small
                            rounded
                            color="green darken-3  white--text mr-3"
                            ><v-icon left small>mdi-plus</v-icon
                            >เพิ่มเงื่อนไขใหม่</v-btn
                          >
                        </v-col>
                        <v-col cols="12">
                          <v-data-table
                            id="mytable"
                            class="elevation-1"
                            :headers="headers"
                            :items="conditions"
                            :items-per-page="itemperpage"
                            dense
                            hide-default-footer
                            :page.sync="conditionPage"
                            @page-count="conditionPageCount = $event">
                            <template v-slot:[`item.condition`]="{ item }">
                              <span v-if="item.condition == 'time'"
                                >เวลา
                                {{ item.variable }}
                              </span>
                              <span v-if="item.condition == 'temperature'"
                                >อุณหภูมิมากกว่า
                                {{ item.variable }}
                                องศา</span
                              >
                              <span v-if="item.condition == 'soil_moisture'"
                                >ค่าความชื้นในดินน้อยกว่า
                                {{ item.variable }}
                                %</span
                              >
                              <span v-if="item.condition == 'humidity'"
                                >ค่าความชื้นในอากาศน้อยกว่า
                                {{ item.variable }}
                                %</span
                              >
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-switch
                                :disabled="userRole === 'guest'"
                                @change="saveUpdateStatus(item.setting_id)"
                                color="green darken-3 ma-0 pa-0"
                                v-model="item.status"
                                dense></v-switch>
                            </template>
                            <template v-slot:[`item.setting`]="{ item }">
                              <v-row class="pl-2">
                                <td>
                                  <v-menu
                                    v-if="userRole !== 'guest'"
                                    bottom
                                    left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-list>
                                      <v-list-item @click="editCondition(item)">
                                        <v-list-item-title>
                                          <v-icon small class="mr-2">
                                            mdi-pencil
                                          </v-icon>
                                          แก้ไข</v-list-item-title
                                        >
                                      </v-list-item>
                                      <v-list-item
                                        @click="
                                          deleteCondition(item.setting_id)
                                        ">
                                        <v-list-item-title
                                          ><v-icon small class="mr-2">
                                            mdi-delete
                                          </v-icon>
                                          ลบ</v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </td>
                              </v-row>
                            </template>
                          </v-data-table>
                          <div class="text-center pt-2">
                            <v-pagination
                              v-model="conditionPage"
                              :length="conditionPageCount"></v-pagination>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
        <v-col cols="12" md="7" :class="checkSize">
          <v-container fluid class="h-100">
            <v-card color="white" elevation="0" rounded="lg" class="h-100">
              <v-card-text>
                <v-container fluid class="px-0">
                  <v-row>
                    <v-col cols="12" md="7">
                      <h3 class="pt-0">
                        รายงานค่าอุณหภูมิ/ความชื้นในอากาศ/ความชื้นในดิน
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6">
                      <date-picker
                        v-model="dates"
                        value-type="YYYY-MM-DD"
                        format="DD-MMM-YYYY"
                        range
                        placeholder="เลือกวันที่ที่ต้องการค้นหา"
                        :style="'width:100% !important'"
                        :disabled-date="disabledDate"></date-picker>
                    </v-col>
                    <!-- <v-col
                                            cols="12"
                                            md="6"
                                            class="text-right"
                                        >
                                            <v-btn-toggle
                                                color="green darken-4"
                                                v-model="summaryChartGroupBy"
                                                rounded
                                                dense
                                                mandatory
                                            >
                                                <v-btn
                                                    v-for="(
                                                        each, idx
                                                    ) in chartGroupBy"
                                                    :key="idx"
                                                    small
                                                    :style="'text-transform: none !important;'"
                                                    :value="each.value"
                                                    >{{ each.title }}
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col> -->
                    <v-col cols="12">
                      <LineChart
                        :chartData="summaryChartData"
                        :options="summaryChartOptions" />
                    </v-col>
                    <v-col cols="12">
                      <v-container fluid class="px-0">
                        <v-row>
                          <v-col cols="12">
                            <h3>Log การทำงานของระบบ</h3>
                          </v-col>
                          <v-col cols="12" md="6">
                            <date-picker
                              v-model="log_dates"
                              value-type="YYYY-MM-DD"
                              format="DD-MMM-YYYY"
                              range
                              :disabled-date="disabledDate"
                              :style="'width:100% !important'"
                              placeholder="เลือกวันที่ที่ต้องการค้นหา"></date-picker>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-data-table
                        id="mytable"
                        class="elevation-1"
                        :headers="headersLog"
                        :items="logs"
                        :items-per-page="itemperpage"
                        dense
                        hide-default-footer
                        :page.sync="logTablePage"
                        @page-count="logTablePageCount = $event">
                        <template v-slot:[`item.log_at`]="{ item }">
                          {{ item.log_at | moment("DD-MMM-YYYY HH:mm") }}
                        </template>
                      </v-data-table>
                      <div class="text-center pt-2">
                        <v-pagination
                          v-model="logTablePage"
                          :length="logTablePageCount"></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog_new_condition" width="800">
      <v-card>
        <v-card-title class="white--text green darken-3">
          เพิ่มเงื่อนไขการทำงานอัตโนมัติใหม่
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-radio-group v-model="new_condition.condition">
                  <v-radio
                    label="ทำงานตามช่วงเวลา"
                    value="time"
                    color="green darken-3"></v-radio>
                  <v-radio
                    label="เมื่ออุณหภูมิ สูงกว่า"
                    value="temperature"
                    color="green darken-3"></v-radio>
                  <v-radio
                    label="เมื่อ % ความชื้นในดิน ต่ำกว่า"
                    value="soil_moisture"
                    color="green darken-3"></v-radio>
                  <v-radio
                    label="เมื่อ % ความชื้นในอากาศ ต่ำกว่า"
                    value="humidity"
                    color="green darken-3"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" v-if="new_condition.condition">
                <v-row>
                  <v-col class="pt-5 pb-0" cols="12">
                    <h4 v-if="new_condition.condition == 'time'">
                      เวลาเริ่มทำงานระบบ
                      <date-picker
                        v-model="new_condition.variable"
                        type="time"
                        value-type="HH:mm"
                        format="HH:mm"
                        placeholder="กรุณาเลือกเวลา"
                        confirm
                        :style="'padding-left:20px !important;width:50% !important'">
                      </date-picker>
                    </h4>
                    <v-text-field
                      v-else
                      dense
                      color="green darken-3"
                      v-model="new_condition.variable"
                      label="ค่าที่ใช้ดำเนินการตามเงื่อนไข"
                      prepend-icon="mdi-tooltip-text-outline"
                      type="number"></v-text-field>
                  </v-col>
                  <v-col class="pb-0 pt-10" cols="12">
                    <v-text-field
                      dense
                      color="green darken-3"
                      v-model="new_condition.timeout"
                      label="ระยะเวลาทำงานของระบบ"
                      prepend-icon="mdi-timer-outline"
                      type="number"
                      clearable
                      suffix="นาที"
                      :rules="valDuration"></v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="6">
                    <v-checkbox
                      v-model="new_condition.system[0]"
                      color="green darken-3"
                      label="ระบบสปริงเกอร์"
                      value="SP"></v-checkbox>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="6">
                    <v-checkbox
                      v-model="new_condition.system[1]"
                      color="green darken-3"
                      label="ระบบพ่นหมอก"
                      value="FG"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            rounded
            color="white--text ml-4 bg-blue-main"
            :disabled="newConditionRule"
            @click="
              dialog_condition_type == 'edit'
                ? saveEditCondition()
                : saveNewCondition()
            "
            ><v-icon left small>mdi-content-save</v-icon>บันทึก</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";
import { LineChart } from "vue-chart-3";
import moment from "moment";

Chart.register(...registerables);

export default {
  name: "FarmDetail",
  components: { LineChart },
  props: ["detail"],
  beforeMount() {
    if (this.checkLatest(this.detail.farms.updated_at) > 0) {
      this.detail.farms.temperature = 0;
      this.detail.farms.humidity = 0;
      this.detail.farms.soil_moisture = 0;
      this.disabled = true;
    }
  },
  data: () => ({
    conditionPage: 1,
    conditionPageCount: 0,
    logTablePage: 1,
    logTablePageCount: 0,
    disabled: false,
    valDuration: [(v) => v > 0 || "ระยะเวลาทำงานของระบบต้องมากกว่า 0 นาที"],
    headersLog: [
      {
        text: "เวลาที่ระบบทำงาน",
        align: "center",
        sortable: false,
        value: "log_at",
      },
      {
        text: "Log การทำงาน",
        align: "left",
        sortable: false,
        value: "logs",
      },
      {
        text: "ผู้สั่งงานระบบ",
        align: "left",
        sortable: false,
        value: "username",
      },
    ],
    headers: [
      {
        text: "เงื่อนไขการทำงาน",
        align: "start",
        sortable: false,
        value: "condition",
      },
      {
        text: "ระยะเวลาทำงานของระบบ (นาที)",
        align: "center",
        sortable: false,
        value: "timeout",
      },
      {
        text: "ระบบที่เปิดทำงาน",
        align: "start",
        sortable: false,
        value: "system_text",
      },
      {
        text: "สถานะการทำงาน",
        align: "center",
        sortable: false,
        value: "actions",
      },
      {
        text: "",
        align: "center",
        sortable: false,
        value: "setting",
      },
    ],
    menu: false,
    destination: "",
    dates: [],
    log_dates: [],
    overlay: false,
    loader: null,
    dialog_new_condition: false,
    new_condition: {
      condition: "",
      variable: "",
      timeout: "",
      system: [],
      status: true,
    },
    summaryChartData: {},
    summaryChartOptions: {},
    summaryChartGroupBy: "day",
    chartGroupBy: [
      {
        value: "hour",
        title: "Hour",
      },
      {
        value: "day",
        title: "Day",
      },
      {
        value: "week",
        title: "Week",
      },
      {
        value: "month",
        title: "Month",
      },
    ],
    tempData: 36,
    tempChartOptions: {
      text: {
        color: "black",
        fontSize: 10,
        textAdjustmentY: 2,
        fontFamily: "Arial",
        textEnabled: false,
      },
      thermo: {
        color: "#7FD1B9",
        backgroundColor: "#ededed",
        frameColor: "#FFFFFF",
        ticks: 6,
        ticksEnabled: false,
        tickColor: "black",
        tickWidth: "1",
      },
      layout: {
        height: 130,
        width: 60,
      },
    },
    exampleValue: 60,
    conditions: [],
    logs: [],
  }),
  mounted() {
    this.logs = this.detail.logs;
    this.summaryChartData = {
      datasets: [
        {
          label: this.$i18n.t("sys.temp"),
          data: this.detail.graph.temperature,
          backgroundColor: "#7FD1B9",
          borderColor: "#7FD1B9",
          tension: 0.2,
        },
        {
          label: this.$i18n.t("sys.moist"),
          data: this.detail.graph.soil_moisture,
          backgroundColor: "#7A6563",
          borderColor: "#7A6563",
          tension: 0.2,
        },
        {
          label: this.$i18n.t("sys.humid"),
          data: this.detail.graph.humidity,
          backgroundColor: "#DE6E4B",
          borderColor: "#DE6E4B",
          tension: 0.2,
        },
      ],
    };
    this.summaryChartOptions = {
      scales: {
        x: {
          type: "time",
          distribution: "series",
          time: {
            unit: this.summaryChartGroupBy,
            displayFormats: {
              hour: "DD-MMM-YY HH:00",
            },
            tooltipFormat: "DD-MMM-YY HH:00",
          },
          min: this.dates[0],
          max: this.dates[1],
        },
        y: {
          suggestedMin: 0,
          suggestedMax: 100,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      tooltips: {
        enabled: true,
      },
    };

    this.reStyleCondition(this.detail.controls);
  },
  computed: {
    newConditionRule() {
      return !(
        this.new_condition.condition &&
        this.new_condition.timeout &&
        this.new_condition.variable &&
        this.new_condition.system.length > 0
      );
    },
    itemperpage() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        return 1;
      }
      return 5;
    },
    checkSize() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "pa-0";
      }
      return "";
    },
  },
  watch: {
    dates: function (newDates, oldDates) {
      if (newDates[0] !== null && newDates[1] !== null) {
        this.updateGraph();
      }
    },
    log_dates: function () {
      this.updateLogs();
    },
    summaryChartGroupBy: function (summaryChartGroupBy) {
      this.summaryChartOptions.scales.x.time.unit = summaryChartGroupBy;
    },
  },
  methods: {
    checkLatest: function (updated_at) {
      let amount = 3;
      if (updated_at) {
        let a = moment();
        let b = moment(updated_at);
        amount = a.diff(b, "hours");
      }
      return amount;
    },
    async reStyleCondition(arrayConditions) {
      arrayConditions.forEach(
        await function (each) {
          let sys_array = [];
          let sys_txt_array = [];
          if (each.system.indexOf("SP") > -1) {
            sys_array[0] = "SP";
            sys_txt_array[0] = "ระบบสปริงเกอร์";
          }
          if (each.system.indexOf("FG") > -1) {
            sys_array[1] = "FG";
            sys_txt_array[1] = "ระบบพ่นหมอก";
          }
          sys_array = sys_array.filter((n) => n);
          sys_txt_array = sys_txt_array.filter((n) => n);
          each.system = sys_array;
          each.system_text = sys_txt_array;

          each.status = each.status == "Active" ? true : false;
        }
      );
      this.conditions = [...arrayConditions];
    },
    saveUpdateStatus: async function (setting_id) {
      this.dialog_condition_type = "new";

      let statusupdate = "";
      this.conditions.map((each) => {
        if (setting_id == each.setting_id) {
          statusupdate = each.status;
        }
      });

      const { data } = await axios.post(
        `${process.env.VUE_APP_API_HOST}/farming/controls/${setting_id}`,
        { status: statusupdate },
        this.bearerToken
      );
    },
    updateGraph: async function () {
      this.loading();
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_HOST}/farming/graph/${this.detail.farming_id}`,
        { dates: this.dates },
        this.bearerToken
      );
      this.summaryChartOptions.scales.x.min = this.dates[0] + " 00:00";
      this.summaryChartOptions.scales.x.max = this.dates[1] + " 23:50";
      this.summaryChartData.datasets[0].data = data.graph.temperature;
      this.summaryChartData.datasets[1].data = data.graph.soil_moisture;
      this.summaryChartData.datasets[2].data = data.graph.humidity;
      this.loading();
    },
    updateLogs: async function () {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_HOST}/farming/logs/${this.detail.farming_id}`,
        { dates: this.log_dates },
        this.bearerToken
      );
      this.logs = data;
    },
    saveNewCondition: async function () {
      // console.log(this.detail)
      this.new_condition.system = this.new_condition.system.join("");

      const { data } = await axios.put(
        `${process.env.VUE_APP_API_HOST}/farming/controls/${this.detail.farming_id}`,
        this.new_condition,
        this.bearerToken
      );
      // this.conditions.push(this.new_condition)
      this.dialog_new_condition = false;
      this.new_condition = {
        condition: "",
        variable: "",
        timeout: "",
        system: [],
        status: true,
      };
      this.getCondition();
    },
    getCondition: async function () {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_HOST}/farming/controls/${this.detail.farming_id}`,
        this.bearerToken
      );
      this.reStyleCondition(data);
    },
    loading() {
      this.overlay = !this.overlay;
    },
    editCondition: async function (item) {
      this.new_condition = item;
      this.dialog_new_condition = true;
      this.dialog_condition_type = "edit";
    },
    saveEditCondition: async function () {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_HOST}/farming/controls-each/${this.new_condition.setting_id}`,
        this.new_condition,
        this.bearerToken
      );
      this.dialog_new_condition = false;
      this.new_condition = {
        condition: "",
        variable: "",
        timeout: "",
        system: [],
        status: true,
      };
      this.getCondition();
    },
    deleteCondition: async function (setting_id) {
      const { data } = await axios.delete(
        `${process.env.VUE_APP_API_HOST}/farming/controls-each/${setting_id}`,
        this.bearerToken
      );
      this.getCondition();
    },
    disabledDate(date) {
      let start = moment(this.detail.farms.start_date)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      return date < new Date(start) || date > new Date();
    },
  },
};
</script>

<style scoped>
.top-right-temptext {
  position: absolute !important;
  top: 40px !important;
  right: 80px !important;
}
.top-right-temp {
  position: absolute !important;
  top: 20px !important;
  right: 16px !important;
}
.top-right-humid {
  position: absolute !important;
  top: 135px !important;
  right: 16px !important;
  width: 30% !important;
}
.top-right-soilm {
  position: absolute !important;
  top: 275px !important;
  right: 16px !important;
  width: 30% !important;
}
</style>
