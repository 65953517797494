<template>
    <div>
        <v-row align="center" justify="center">
            <v-col cols="10" sm="8" md="4">
                <v-card
                    color="#EEEEEE"
                    light
                    outlined
                    class="pa-5"
                    elevation="0"
                    rounded="xl"
                >
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col cols="10" class="text-center">
                                <h1 class="black--text">SMART FARMING</h1>
                            </v-col>
                            <v-col cols="10" class="mt-4 pb-0">
                                <v-text-field
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="Username / รหัสผู้ใช้งาน"
                                    v-model="userLogin"
                                    v-on:keyup.enter="login()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="10" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="Password / รหัสผ่าน"
                                    type="password"
                                    v-model="passwordLogin"
                                    v-on:keyup.enter="login()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="10">
                                <v-btn
                                    @click="login()"
                                    block
                                    dark
                                    elevation="5"
                                    color="green darken-3"
                                    rounded
                                >
                                    <v-icon class="mr-5">mdi-power</v-icon>
                                    เข้าสู่ระบบ
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <p class="black--text">
                                    &copy;
                                    {{ new Date().getFullYear() }} Turnkey
                                    Communication Services Public Company
                                    Limited
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from "axios"

export default {
    data: () => ({
        userLogin: "",
        passwordLogin: "",
    }),
    methods: {
        async login() {
            // this.$swal("loading", {
            //     config: {
            //         loading: {
            //             text: "loading...",
            //         },
            //     },
            // })
            this.$swal({
                title: "กำลังเข้าสู่ระบบ...",
                icon: "loading",
                showConfirmButton: false,
                timer: 3000,
            })
            const { data } = await axios.post(
                `${process.env.VUE_APP_API_HOST}/auth/login`,
                {
                    username: this.userLogin,
                    password: this.passwordLogin,
                }
            )

            if (data === undefined) {
                await this.$swal({
                    title: "ชื่อผู้ใช้งานหรือรหัสผ่านผิด กรุณาลองใหม่อีกครั้ง",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })

                return
            }

            localStorage.token_smf = data.token
            localStorage.user_lang = data.user.lang
            localStorage.user_role = data.user.role
            localStorage.company_id = data.user.company_id
            localStorage.company_name = data.user.company.company_name
            localStorage.lat = data.user.company.lat
            localStorage.lng = data.user.company.lng
            this.$store.commit("setLogInStatus", true)
            window.location.href = "/Farm"
        },
    },
    beforeCreate() {
        if (localStorage.token_smf) {
            window.location.href = "/Farm"
        }
    },
}
</script>

<style scoped></style>
