<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                width="7"
                size="64"
                color="light-green"
            ></v-progress-circular>
        </v-overlay>
        <v-container fluid v-if="page_status == 'main'">
            <v-row v-if="!load_data">
                <v-col cols="12" md="3">
                    <h3>
                        <v-icon class="mr-4">mdi-sprout</v-icon>
                        {{ $t("farm.title") }}
                    </h3>
                </v-col>
                <v-col cols="10" md="6" class="mt-2 pt-0 align-center">
                    <v-chip-group
                        mandatory
                        show-arrows
                        v-model="showPlant"
                        active-class="primary--text"
                    >
                        <v-chip v-for="(each, idx) in farms" :key="idx">
                            {{ each.plant_name }}
                        </v-chip>
                    </v-chip-group>
                </v-col>
                <v-col cols="2" md="3" class="text-right">
                    <v-menu bottom left v-if="userRole !== 'guest'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list :width="200" dense>
                            <v-list-item
                                dense
                                @click="dialog_new_farming = true"
                            >
                                <v-list-item-title>
                                    <v-icon left small>mdi-plus</v-icon>
                                    เพิ่มการปลูกใหม่</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="showCollectFarming()">
                                <v-list-item-title>
                                    <v-icon left small>mdi-check</v-icon>
                                    เก็บเกี่ยวผลผลิต</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="showControlIoT()">
                                <v-list-item-title>
                                    <v-icon left small>mdi-dip-switch</v-icon>
                                    สั่งงานระบบ IoT</v-list-item-title
                                >
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col cols="12">
                    <v-window v-model="showPlant" vertical>
                        <v-window-item v-for="(each, idx) in farms" :key="idx">
                            <v-row align="center">
                                <v-col
                                    cols="12"
                                    md="6"
                                    lg="4"
                                    v-for="(farm, idx2) in each.farmings"
                                    :key="idx2"
                                >
                                    <Glasshouse
                                        :visibleSubtitle="false"
                                        :farm="farm"
                                        @showSub="showSub"
                                        @showControl="showControl"
                                    />
                                </v-col>
                            </v-row>
                        </v-window-item>
                    </v-window>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="6" lg="4" v-for="idx in 12" :key="idx">
                    <v-skeleton-loader
                        type="article, actions"
                        loading
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid v-else-if="page_status == 'sub'">
            <v-row v-if="!load_data">
                <v-col cols="7">
                    <h3>
                        <v-icon class="mr-4">mdi-home-variant-outline</v-icon>
                        <span>{{ farms_sub.farms.farm_name }}</span>
                    </h3>
                </v-col>
                <v-col cols="5" class="text-right">
                    <v-btn
                        small
                        rounded
                        elevation="0"
                        color="grey lighten-1"
                        @click="showMain()"
                        class=""
                    >
                        <v-icon>mdi-chevron-left</v-icon> กลับหน้าหลัก
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <FarmDetail :detail="farms_sub" />
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="6" v-for="idx in 2" :key="idx">
                    <v-skeleton-loader
                        height="1000"
                        type="article, actions"
                        loading
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>

        <!-- DIALOG CONTROL SYSTEM -->
        <v-dialog v-model="dialog_control" width="500">
            <v-card>
                <v-card-title class="white--text green darken-3">
                    สั่งงานระบบ IoT แบบ Manual
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col
                                cols="6"
                                class="pt-6 pb-0 mb-0"
                                align="center"
                            >
                                <Transition name="fade">
                                    <v-img
                                        v-if="control.fog"
                                        width="50%"
                                        transition="fade-transition"
                                        src="../assets/icon/fog.png"
                                    />
                                </Transition>
                                <Transition name="fade">
                                    <v-img
                                        v-if="!control.fog"
                                        width="50%"
                                        transition="fade-transition"
                                        src="../assets/icon/fog-outline.png"
                                    />
                                </Transition>
                            </v-col>
                            <v-col
                                cols="6"
                                class="pt-6 pb-0 mb-0"
                                align="center"
                            >
                                <Transition name="fade">
                                    <v-img
                                        v-if="control.sprinkler"
                                        width="50%"
                                        transition="fade-transition"
                                        src="../assets/icon/sprinkler.png"
                                    />
                                </Transition>
                                <Transition name="fade">
                                    <v-img
                                        v-if="!control.sprinkler"
                                        width="50%"
                                        transition="fade-transition"
                                        src="../assets/icon/sprinkler-outline.png"
                                    />
                                </Transition>
                            </v-col>
                            <v-col cols="6" class="pt-0 mt-0 justify-center">
                                <v-switch
                                    :disabled="userRole === 'guest'"
                                    color="green darken-3"
                                    v-model="control.fog"
                                    :label="`ระบบพ่นหมอก`"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6" class="pt-0 mt-0 justify-center">
                                <v-switch
                                    :disabled="userRole === 'guest'"
                                    color="green darken-3"
                                    v-model="control.sprinkler"
                                    :label="`ระบบสปริงเกอร์`"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" class="px-0 py-2">
                                <h3>ระยะเวลาทำงานของระบบ</h3>
                            </v-col>
                            <v-col cols="12" md="8" class="pa-0">
                                <v-text-field
                                    class="pt-0"
                                    :disabled="userRole === 'guest'"
                                    v-model="control.duration"
                                    label=""
                                    color="green darken-3"
                                    prepend-icon="mdi-clock"
                                    type="number"
                                    clearable
                                    suffix="นาที"
                                    :rules="valDuration"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="closeControl"
                        small
                        rounded
                        color="black--text grey lighten-2"
                        ><v-icon left small>mdi-close</v-icon>ยกเลิก</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="userRole !== 'guest'"
                        :disabled="startControlRule"
                        :loading="loading_start_control"
                        @click="startControl"
                        small
                        rounded
                        color="white--text bg-blue-main"
                        ><v-icon left small>mdi-power</v-icon
                        >เริ่มสั่งงาน</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DIALOG NEW FARMING -->
        <v-dialog
            @click:outside="resetFarming"
            v-model="dialog_new_farming"
            width="550px"
            max-width="85%"
        >
            <v-stepper v-model="new_step">
                <v-stepper-header>
                    <v-stepper-step
                        color="bg-green-main"
                        :complete="new_step > 1"
                        step="1"
                    >
                        พืช
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step color="bg-green-main" step="2"
                        >โรงเรือน
                    </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" class="px-0 py-2">
                                            <h3>
                                                จำนวนต้นที่ปลูกในแต่ละโรงเรือน
                                            </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" class="pa-0">
                                            <v-text-field
                                                class="pt-0"
                                                v-model="new_farming.qty"
                                                label=""
                                                color="green darken-3"
                                                prepend-icon="mdi-sprout"
                                                type="number"
                                                clearable
                                                suffix="ต้น"
                                                :rules="valQty"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-item-group v-model="new_farming.plant_id">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" class="px-0 py-2">
                                                <h3>กรุณาเลือกพืชที่จะปลูก</h3>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            class="scrolling-container-small"
                                        >
                                            <v-col
                                                class="pl-0 pr-2 pb-2 pt-0"
                                                cols="12"
                                                v-for="item in master.plants"
                                                :key="item.plant_id"
                                            >
                                                <v-item
                                                    v-slot="{ active, toggle }"
                                                    :value="item.plant_id"
                                                >
                                                    <v-card
                                                        :color="
                                                            active
                                                                ? 'bg-green-main'
                                                                : 'grey lighten-3 black--text'
                                                        "
                                                        class="d-flex align-center"
                                                        dark
                                                        height="40"
                                                        @click="toggle"
                                                    >
                                                        <v-scroll-y-transition>
                                                            <div
                                                                class="text-h6 flex-grow-1 text-center"
                                                            >
                                                                {{
                                                                    item.plant_name
                                                                }}
                                                            </div>
                                                        </v-scroll-y-transition>
                                                    </v-card>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-item-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="resetFarming" text>
                                    ยกเลิก
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    small
                                    rounded
                                    color="white--text bg-green-main"
                                    @click="new_step = 2"
                                    :disabled="plantStep1"
                                >
                                    ขั้นตอนถัดไป
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-item-group
                                    v-model="new_farming.farm_id"
                                    multiple
                                >
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" class="px-0 py-2">
                                                <h3>
                                                    กรุณาเลือกโรงเรือนที่จะปลูก
                                                </h3>
                                            </v-col>
                                        </v-row>
                                        <v-row class="scrolling-container">
                                            <v-col
                                                class="pl-0 pr-2 pb-2 pt-0"
                                                cols="12"
                                                v-for="item in master.farms"
                                                :key="item.farm_id"
                                            >
                                                <v-item
                                                    v-slot="{ active, toggle }"
                                                    :value="item.farm_id"
                                                >
                                                    <v-card
                                                        :color="
                                                            active
                                                                ? 'bg-green-main'
                                                                : 'grey lighten-3 black--text'
                                                        "
                                                        class="d-flex align-center"
                                                        dark
                                                        height="40"
                                                        @click="toggle"
                                                    >
                                                        <v-scroll-y-transition>
                                                            <div
                                                                class="text-h6 flex-grow-1 text-center"
                                                            >
                                                                {{
                                                                    item.farm_name
                                                                }}
                                                            </div>
                                                        </v-scroll-y-transition>
                                                    </v-card>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-item-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="new_step = 1" text>
                                    กลับไปขั้นตอนก่อนหน้า
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :loading="loading_save_farming"
                                    :disabled="plantStep2"
                                    @click="saveFarming"
                                    small
                                    rounded
                                    color="white--text bg-green-main"
                                    ><v-icon left small>mdi-floppy</v-icon
                                    >บันทึกข้อมูล</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <!-- DIALOG COLLECT FARMING -->
        <v-dialog
            @click:outside="resetCollectFarming"
            v-model="dialog_collect_farming"
            width="550px"
            max-width="85%"
        >
            <v-stepper v-model="collect_step">
                <v-stepper-header>
                    <v-stepper-step
                        color="bg-green-main"
                        :complete="collect_step > 1"
                        step="1"
                    >
                        โรงเรือน
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step color="bg-green-main" step="2">
                        ข้อมูลการเก็บเกี่ยว
                    </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" class="px-0 py-2">
                                            <h3>
                                                กรุณาเลือกโรงเรือนที่เก็บเกี่ยว
                                            </h3>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-item-group
                                    multiple
                                    v-model="collect_farming.farming_id"
                                >
                                    <v-container
                                        fluid
                                        class="scrolling-container"
                                    >
                                        <v-row
                                            v-for="each in showCollect"
                                            :key="each.plant_id"
                                        >
                                            <v-col cols="12" class="px-0 py-2">
                                                <h3>{{ each.plant_name }}</h3>
                                            </v-col>
                                            <v-col
                                                v-for="farm in each.farmings"
                                                :key="farm.farming_id"
                                                cols="12"
                                                class="pl-0 pr-2 pb-2 pt-0"
                                            >
                                                <v-item
                                                    v-slot="{ active, toggle }"
                                                    :value="farm.farming_id"
                                                >
                                                    <v-card
                                                        :color="
                                                            active
                                                                ? 'bg-green-main'
                                                                : 'grey lighten-3 black--text'
                                                        "
                                                        class="d-flex align-center"
                                                        dark
                                                        height="40"
                                                        @click="toggle"
                                                    >
                                                        <div
                                                            class="text-h6 flex-grow-1 text-center"
                                                        >
                                                            {{
                                                                farm.farm
                                                                    .farm_name
                                                            }}
                                                        </div>
                                                    </v-card>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-item-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="resetCollectFarming" text>
                                    ยกเลิก
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="collectStep1"
                                    small
                                    rounded
                                    color="white--text bg-green-main"
                                    @click="collect_step = 2"
                                >
                                    ขั้นตอนถัดไป
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text class="pa-0">
                                <v-container
                                    fluid
                                    class="px-0 scrolling-container"
                                >
                                    <v-row align="center">
                                        <v-col
                                            cols="12"
                                            align="center"
                                            class="mini-text"
                                        >
                                            <h3>
                                                วันที่เก็บเกี่ยว :
                                                <date-picker
                                                    v-model="
                                                        collect_farming.collect_date
                                                    "
                                                    value-type="YYYY-MM-DD"
                                                    format="DD-MMM-YYYY"
                                                    placeholder="เลือกวันที่เก็บเกี่ยว"
                                                ></date-picker>
                                            </h3>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-for="(
                                            plant, idx
                                        ) in collect_farming.plants"
                                        :key="idx"
                                        class="justify-center"
                                    >
                                        <v-col cols="12" align="center">
                                            <v-container fluid class="px-0">
                                                <v-row align="center">
                                                    <v-col
                                                        cols="12"
                                                        align="center"
                                                        class="mini-text"
                                                    >
                                                        <h3>
                                                            {{
                                                                plant.plant_name
                                                            }}
                                                        </h3>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    class="rounded-lg row-border"
                                                    align="center"
                                                >
                                                    <v-col
                                                        class="pt-0"
                                                        cols="12"
                                                        align="center"
                                                    >
                                                        <h4>
                                                            เกรด A :
                                                            ส่งขายกรุงเทพฯ
                                                        </h4>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                        align="center"
                                                        class="mini-text py-0"
                                                    >
                                                        <v-text-field
                                                            class="pt-0"
                                                            v-model="
                                                                plant.grade_a
                                                            "
                                                            label="ปริมาณ"
                                                            color="green darken-3"
                                                            prepend-icon="mdi-weight"
                                                            type="number"
                                                            clearable
                                                            suffix="กิโลกรัม"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                        align="center"
                                                        class="mini-text py-0"
                                                    >
                                                        <v-text-field
                                                            class="pt-0"
                                                            v-model="
                                                                plant.price_grade_a
                                                            "
                                                            label="ราคาต่อกิโลกรัม"
                                                            color="green darken-3"
                                                            prepend-icon="mdi-currency-thb"
                                                            type="number"
                                                            clearable
                                                            suffix="บาท"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    class="rounded-lg row-border"
                                                    align="center"
                                                >
                                                    <v-col
                                                        class="pt-0"
                                                        cols="12"
                                                        align="center"
                                                    >
                                                        <h4>
                                                            เกรด B :
                                                            ส่งขายในชุมชน
                                                        </h4>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                        align="center"
                                                        class="mini-text py-0"
                                                    >
                                                        <v-text-field
                                                            class="pt-0"
                                                            v-model="
                                                                plant.grade_b
                                                            "
                                                            label="ปริมาณ"
                                                            color="green darken-3"
                                                            prepend-icon="mdi-weight"
                                                            type="number"
                                                            clearable
                                                            suffix="กิโลกรัม"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                        align="center"
                                                        class="mini-text py-0"
                                                    >
                                                        <v-text-field
                                                            class="pt-0"
                                                            v-model="
                                                                plant.price_grade_b
                                                            "
                                                            label="ราคาต่อกิโลกรัม"
                                                            color="green darken-3"
                                                            prepend-icon="mdi-currency-thb"
                                                            type="number"
                                                            clearable
                                                            suffix="บาท"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    class="rounded-lg row-border"
                                                    align="center"
                                                >
                                                    <v-col
                                                        class="pt-0"
                                                        cols="12"
                                                        align="center"
                                                    >
                                                        <h4>
                                                            เกรด C :
                                                            แยกไว้ทำปุ๋ย
                                                        </h4>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                        align="center"
                                                        class="mini-text py-0"
                                                    >
                                                        <v-text-field
                                                            class="pt-0"
                                                            v-model="
                                                                plant.grade_c
                                                            "
                                                            label="ปริมาณ"
                                                            color="green darken-3"
                                                            prepend-icon="mdi-weight"
                                                            type="number"
                                                            clearable
                                                            suffix="กิโลกรัม"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="6"
                                                        align="center"
                                                        class="mini-text py-0"
                                                    >
                                                        <v-text-field
                                                            class="pt-0"
                                                            v-model="
                                                                plant.price_grade_c
                                                            "
                                                            label="ราคาต่อกิโลกรัม"
                                                            color="green darken-3"
                                                            prepend-icon="mdi-currency-thb"
                                                            type="number"
                                                            clearable
                                                            suffix="บาท"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="collect_step = 1" text>
                                    กลับไปขั้นตอนก่อนหน้า
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="collectStep2"
                                    @click="saveCollectFarming()"
                                    small
                                    rounded
                                    color="white--text bg-green-main"
                                >
                                    บันทึกข้อมูล
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <!-- DIALOG CONTROL IOT -->
        <v-dialog
            @click:outside="resetControlIoT"
            v-model="dialog_control_iot"
            width="550px"
            max-width="85%"
        >
            <v-stepper v-model="control_step">
                <v-stepper-header>
                    <v-stepper-step
                        color="bg-green-main"
                        :complete="control_step > 1"
                        step="1"
                    >
                        โรงเรือน
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step color="bg-green-main" step="2">
                        รูปแบบการสั่งงาน
                    </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-item-group
                                    multiple
                                    v-model="control_iot.farms"
                                >
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="12" class="px-0 py-2">
                                                <h3>
                                                    กรุณาเลือกโรงเรือนที่จะสั่งงาน
                                                    IoT
                                                </h3>
                                                <h4 color="grey">
                                                    (เลือกได้สูงสุดไม่เกิน 6
                                                    โรงเรือนต่อ 1 ครั้ง)
                                                </h4>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            class="scrolling-container"
                                            v-if="farms.length > 0"
                                        >
                                            <v-col
                                                class="pl-0 pr-2 pb-2 pt-0"
                                                cols="12"
                                                v-for="item in farms[0]
                                                    .farmings"
                                                :key="item.farm.farm_id"
                                            >
                                                <v-item
                                                    v-slot="{ active, toggle }"
                                                    :value="item.farm.farm_id"
                                                >
                                                    <v-card
                                                        :color="
                                                            active
                                                                ? 'bg-green-main'
                                                                : 'grey lighten-3 black--text'
                                                        "
                                                        class="d-flex align-center"
                                                        dark
                                                        @click="toggle"
                                                        height="40"
                                                    >
                                                        <div
                                                            class="text-h6 flex-grow-1 text-center"
                                                        >
                                                            {{
                                                                item.farm
                                                                    .farm_name
                                                            }}
                                                        </div>
                                                    </v-card>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-item-group>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="resetControlIoT" text>
                                    ยกเลิก
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="controlStep1"
                                    small
                                    rounded
                                    color="white--text bg-green-main"
                                    @click="control_step = 2"
                                >
                                    ขั้นตอนถัดไป
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text class="py-0">
                                <v-container fluid class="px-0">
                                    <v-row align="center">
                                        <v-col
                                            cols="6"
                                            class="pt-6 pb-0 mb-0"
                                            align="center"
                                        >
                                            <Transition name="fade">
                                                <v-img
                                                    v-if="control_iot.fog"
                                                    width="50%"
                                                    transition="fade-transition"
                                                    src="../assets/icon/fog.png"
                                                />
                                            </Transition>
                                            <Transition name="fade">
                                                <v-img
                                                    v-if="!control_iot.fog"
                                                    width="50%"
                                                    transition="fade-transition"
                                                    src="../assets/icon/fog-outline.png"
                                                />
                                            </Transition>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            class="pt-6 pb-0 mb-0"
                                            align="center"
                                        >
                                            <Transition name="fade">
                                                <v-img
                                                    v-if="control_iot.sprinkler"
                                                    width="50%"
                                                    transition="fade-transition"
                                                    src="../assets/icon/sprinkler.png"
                                                />
                                            </Transition>
                                            <Transition name="fade">
                                                <v-img
                                                    v-if="
                                                        !control_iot.sprinkler
                                                    "
                                                    width="50%"
                                                    transition="fade-transition"
                                                    src="../assets/icon/sprinkler-outline.png"
                                                />
                                            </Transition>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            class="pt-0 mt-0 justify-center"
                                        >
                                            <v-switch
                                                :disabled="userRole === 'guest'"
                                                color="green darken-3"
                                                v-model="control_iot.fog"
                                                :label="`ระบบพ่นหมอก`"
                                            ></v-switch>
                                        </v-col>
                                        <v-col
                                            cols="6"
                                            class="pt-0 mt-0 justify-center"
                                        >
                                            <v-switch
                                                :disabled="userRole === 'guest'"
                                                color="green darken-3"
                                                v-model="control_iot.sprinkler"
                                                :label="`ระบบสปริงเกอร์`"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12" class="px-0 py-2">
                                            <h3>ระยะเวลาทำงานของระบบ</h3>
                                        </v-col>
                                        <v-col cols="12" md="8" class="pa-0">
                                            <v-text-field
                                                class="pt-0"
                                                :disabled="userRole === 'guest'"
                                                v-model="control_iot.duration"
                                                label=""
                                                color="green darken-3"
                                                prepend-icon="mdi-clock"
                                                type="number"
                                                clearable
                                                suffix="นาที"
                                                :rules="valDuration"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="control_step = 1" text>
                                    กลับไปขั้นตอนก่อนหน้า
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :loading="loading_control_iot"
                                    :disabled="controlStep2"
                                    @click="saveControlIoT()"
                                    small
                                    rounded
                                    color="white--text bg-green-main"
                                >
                                    สั่งงาน IoT
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios"
import Glasshouse from "@/components/Glasshouse.vue"
import Plant from "@/components/Plant.vue"
import FarmDetail from "./FarmDetail"
import DatePicker from "vue2-datepicker"

export default {
    name: "Farm",
    components: { FarmDetail, Glasshouse, Plant, DatePicker },
    computed: {},
    data: () => ({
        valDuration: [
            (v) => (v > 0 && v < 31) || "ค่าต้องอยู่ระหว่าง 1 - 30 นาที",
        ],
        valQty: [(v) => v > 0 || "ค่าต้องมีค่ามากกว่า 0"],
        showPlant: 0,
        load_data: true,
        overlay: false,
        docState: "saved",
        page_status: "main",
        menu: false,
        loading_start_control: false,
        loading_save_farming: false,
        loading_control_iot: false,
        dialog_control: false,
        dialog_new_farming: false,
        dialog_collect_farming: false,
        show_farm: {},
        switch1: true,
        master: {
            plants: [],
            farms: [],
        },
        new_farming: {
            qty: 0,
            plant_id: "",
            farming_setting: [],
            farm_id: [],
        },
        collect_farming: {
            farming_id: [],
            collect_date: "",
            plants: [],
        },
        control: {
            sprinkler: "",
            fog: "",
            duration: "",
        },
        view_as: "Plant",
        new_condition: {
            condition: "",
            time: "",
            value: "",
            duration: "",
            system: [],
            status: true,
        },
        farms: [],
        plants: [],
        new_step: 1,
        collect_step: 1,
        showCollect: [],
        control_step: 1,
        dialog_control_iot: false,
        control_iot: {
            farms: [],
            sprinkler: "",
            fog: "",
            duration: "",
        },
    }),
    async mounted() {
        await this.getMasterData()
        await this.getData()
    },
    computed: {
        startControlRule() {
            return !(
                (this.control.sprinkler || this.control.fog) &&
                this.control.duration > 0 &&
                this.control.duration < 31
            )
        },
        plantStep1() {
            return !(this.new_farming.plant_id && this.new_farming.qty > 0)
        },
        plantStep2() {
            return !this.new_farming.farm_id.length > 0
        },
        collectStep1() {
            return !this.collect_farming.plants.length > 0
        },
        collectStep2() {
            return !this.collect_farming.collect_date
        },
        controlStep1() {
            return !(
                this.control_iot.farms.length > 0 &&
                this.control_iot.farms.length < 7
            )
        },
        controlStep2() {
            return !(
                (this.control_iot.sprinkler || this.control_iot.fog) &&
                this.control_iot.duration > 0 &&
                this.control_iot.duration < 31
            )
        },
    },
    methods: {
        async getMasterData() {
            const { data } = await axios.get(
                `${process.env.VUE_APP_API_HOST}/farming`,
                this.bearerToken
            )
            this.master.farms = data.farms
            this.master.plants = data.plants
        },
        async getData() {
            this.load_data = true

            const { data } = await axios.post(
                `${process.env.VUE_APP_API_HOST}/farming`,
                {
                    view_as: this.view_as,
                },
                this.bearerToken
            )

            let arrayData = data.data
            let tmp = []
            arrayData.map((each) => {
                tmp = tmp.concat(each.farmings)
            })

            tmp = tmp.sort((a, b) => {
                if (a.farm.farm_name < b.farm.farm_name) {
                    return -1
                }
            })

            arrayData.unshift({
                farmings: tmp,
                plant_name: "ดูทั้งหมด",
                plant_id: 0,
            })

            this.farms = arrayData
            this.load_data = false
        },
        showControl: function (value) {
            this.dialog_control = true
            this.show_farm = { ...value }
        },
        startControl: async function () {
            this.loading_start_control = true

            await axios.post(
                `${process.env.VUE_APP_API_HOST}/farming/mqtt-control/${this.show_farm.farm.farm_id}`,
                this.control,
                this.bearerToken
            )
            this.loading_start_control = false
            this.control = {
                sprinkler: "",
                fog: "",
                duration: "",
            }
            this.dialog_control = false
        },
        closeControl: function (value) {
            this.control = {
                sprinkler: "",
                fog: "",
                duration: "",
            }
            this.dialog_control = false
        },
        showCollectFarming: async function () {
            this.dialog_collect_farming = true
            const { data } = await axios.post(
                `${process.env.VUE_APP_API_HOST}/farming`,
                {
                    view_as: "Collect",
                },
                this.bearerToken
            )
            this.showCollect = [...data.data]
        },
        saveSetting: function () {
            this.dialog_setting = false
        },
        saveNewCondition: function () {
            this.show_farm.conditions.push(this.new_condition)
            this.dialog_new_farming = false
            this.new_condition = {
                condition: "",
                time: "",
                value: "",
                duration: "",
                system: [],
                status: true,
            }
        },
        showSub: async function (value) {
            this.load_data = true
            this.page_status = "sub"
            this.loading()
            const { data } = await axios.get(
                `${process.env.VUE_APP_API_HOST}/farming/${value.farming_id}`,
                this.bearerToken
            )
            this.loading()
            this.farms_sub = data
            this.load_data = false
        },
        showMain: async function () {
            this.page_status = "main"
            this.loading()
            this.getData()
            this.loading()
        },
        resetFarming: function () {
            this.dialog_new_farming = false
            this.new_farming = {
                qty: 0,
                plant_id: "",
                farming_setting: [],
                farm_id: [],
            }
            this.new_step = 1
        },
        saveFarming: async function () {
            this.loading_save_farming = true

            await axios.put(
                `${process.env.VUE_APP_API_HOST}/farming`,
                this.new_farming,
                this.bearerToken
            )
            this.loading_save_farming = false
            this.resetFarming()
            this.getMasterData()
            this.getData()
        },
        resetCollectFarming: function () {
            this.dialog_collect_farming = false
            this.collect_farming = {
                farming_id: [],
                collect_date: "",
                plants: [],
            }
            this.collect_step = 1
        },
        saveCollectFarming: async function () {
            this.loading_save_farming = true

            await axios.post(
                `${process.env.VUE_APP_API_HOST}/farming/collect-farming`,
                this.collect_farming,
                this.bearerToken
            )
            this.loading_save_farming = false
            this.resetCollectFarming()
            this.getData()
            this.getMasterData()
        },
        refreshData() {
            this.getData()
        },
        loading() {
            this.overlay = !this.overlay
        },
        showControlIoT: async function () {
            this.dialog_control_iot = true
        },
        resetControlIoT: function () {
            this.dialog_control_iot = false
            this.control_iot = {
                farms: [],
                sprinkler: "",
                fog: "",
                duration: "",
            }
            this.control_step = 1
        },
        saveControlIoT: async function () {
            this.loading_control_iot = true

            await axios.post(
                `${process.env.VUE_APP_API_HOST}/farming/mqtt-control-bulk`,
                this.control_iot,
                this.bearerToken
            )
            this.loading_control_iot = false
            this.resetControlIoT()
        },
    },
    watch: {
        view_as(val) {
            this.getData()
        },
        "new_farming.plant_id"(val) {
            this.master.plants.map((plant) => {
                if (plant.plant_id == val) {
                    // console.log(plant.plants_settings)
                    this.new_farming.farming_setting = [
                        ...plant.plants_settings,
                    ]
                }
            })
            this.collect_farming.plants = plant_array
        },
        "collect_farming.farming_id"(val) {
            let plant_array = []
            this.showCollect.forEach((plant) => {
                plant.farmings.forEach((farming) => {
                    let found = plant_array.some(
                        (el) => el.plant_id === plant.plant_id
                    )
                    if (val.indexOf(farming.farming_id) > -1 && !found) {
                        let temp = {
                            info_id: farming.farming_info.info_id,
                            plant_id: plant.plant_id,
                            plant_name: plant.plant_name,
                            grade_a: 0,
                            grade_b: 0,
                            grade_c: 0,
                            grade_d: 0,
                            price_grade_a: 0,
                            price_grade_b: 0,
                            price_grade_c: 0,
                            price_grade_d: 0,
                        }
                        plant_array.push(temp)
                    }
                })
            })
            this.collect_farming.plants = plant_array
        },
    },
}
</script>

<style scoped>
.scrolling-container-small {
    overflow-y: scroll;
    max-height: 200px;
}
.scrolling-container {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}
.border-right {
    border-right: 1px solid #dddddd;
}

.row-border {
    border: 1px solid rgb(219, 219, 219);
    margin: 5px 5px 5px 0px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.6s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
