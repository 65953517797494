<template>
    <div>
        <ErrorPage :info="info" />
    </div>
</template>

<script>
import ErrorPage from "@/components/ErrorPage.vue"
export default {
    name: "Notfound",
    components: { ErrorPage },
    data: () => ({
        info: {
            status: "404",
            title: "หน้าเว็บไซต์ที่คุณเข้าถึงไม่มีอยู่ในระบบ",
            subtitle:
                "กรุณารอสักครู่ ระบบกำลังดำเนินการกลับสู่หน้าหลัก หรือ กดปุ่มด้านล่างเพื่อกลับสู่หน้าหลัก",
            btn: "กลับสู่หน้าหลัก",
        },
    }),
}
</script>
