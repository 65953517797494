import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        internetConnectionStatus: "",
        isLoggedIn: true,
        lang: "th",
        farmSensors: [],
        notifications: [],
    },
    getters: {
        internetConnectionStatus: (state) => state.internetConnectionStatus,
        isLoggedIn: (state) => state.isLoggedIn,
        lang: (state) => state.lang,
        farmSensors: (state) => (dev_eui) => {
            let obj = {
                temperature: "",
                humidity: "",
                soil_moisture: "",
                soil_ec: "",
                dev_eui: "",
                last_updated: "",
                animated: "",
                disabled: true,
            }
            state.farmSensors.map((each) => {
                if (each.dev_eui == dev_eui) {
                    obj = { ...each }
                }
            })
            return obj
        },
        notifications: (state) => state.notifications,
    },
    mutations: {
        setInternetConnection(state, status) {
            state.internetConnectionStatus = status
        },
        setLogInStatus(state, status) {
            state.isLoggedIn = status
        },
        setLang(state, status) {
            state.lang = status
            this.$moment.locale(status)
        },
        setNotifications(state, notifications) {
            state.notifications.unshift(notifications)
        },
        setFarmSensors(state, data) {
            state.farmSensors.map((each, idx) => {
                if (each.dev_eui == data.dev_eui) {
                    state.farmSensors.splice(idx, 1)
                }
            })
            state.farmSensors.push(data)
        },
        clearNotifications(state) {
            state.notifications = []
        },
    },

    actions: {},
    modules: {},
})
