<template>
    <v-card class="pa-1" elevation="0" rounded="lg">
        <v-card-title class="pa-2 justify-space-between">
            {{ farm.farm_name }}
            <v-btn elevation="0" fab x-small color="primary">
                <v-icon dark> mdi-magnify </v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 pt-1">
            <v-timeline
                class="ml-n6"
                v-if="farm.histories.length > 0"
                align-top
                dense
            >
                <v-timeline-item
                    v-for="(history, idx) in farm.histories"
                    :key="idx"
                    :icon="'mdi-sprout'"
                    color="green darken-3"
                    small
                    fill-dot
                >
                    <div>
                        <h2
                            :class="`text-headline font-weight-light mb-0 green--text darken-3`"
                        >
                            {{ history.plant_name }}
                        </h2>
                        <div>
                            {{ history.start_date }} -
                            {{ history.end_date }}
                        </div>
                    </div>
                </v-timeline-item>
            </v-timeline>
            <p v-else :class="`font-weight-light grey--text text-center`">
                - ยังไม่มีข้อมูลการเพาะปลูกในขณะนี้ -
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "HistoryGlasshouse",
    props: ["farm"],
    mounted() {},
    data: () => ({}),
    methods: {},
    beforeCreate() {},
}
</script>

<style scoped></style>
