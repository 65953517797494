<template>
    <v-container fluid>
        <Glasshouse
            v-for="(farm, idx) in plants.farmings"
            :key="idx"
            :visibleActions="false"
            :visibleSubtitle="false"
            :farm="farm"
        />
    </v-container>
</template>

<script>
import Glasshouse from "@/components/Glasshouse.vue"

export default {
    name: "Plants",
    props: ["plants"],
    components: { Glasshouse },
    mounted() {
        // this.plants.map((plt, plt_id) => {
        //     let obj = {
        //         farming_id: "",
        //         farming_id: "",
        //         farm_id: "",
        //         farm_name: "",
        //         plant_name: "",
        //         updated_at: "",
        //         start_date: "",
        //         humidity: "",
        //         temperature: "",
        //         soil_moisture: "",
        //         farming_status: "",
        //     }
        //     this.plants[cat_id].plants[plt_id].farm_new = []
        //     plt.farmings.map((frmg, frmg_id) => {
        //         frmg.farming_details.map((frm_detail) => {
        //             obj.farming_id = frm_detail.farming_id
        //             obj.farming_id = frm_detail.farming_id
        //             obj.farm_id = frm_detail.farm_id
        //             obj.farm_name = frm_detail.farm.farm_name
        //             obj.updated_at = frm_detail.updated_at
        //             obj.start_date = frm_detail.start_date
        //             obj.humidity = frm_detail.humidity
        //             obj.temperature = frm_detail.temperature
        //             obj.soil_moisture = frm_detail.soil_moisture
        //             obj.farming_status = frmg.farming_status
        //         })
        //         this.plants[plt_id].farm_new.push(obj)
        //     })
        // })
    },

    methods: {
        // clickSub: function (val) {
        //     this.$mqtt.subscribe("application/3/device/a67b75ca96b26f44/#")
        // },
        // clickPub: function (val) {
        //     this.$mqtt.publish(
        //         "application/3/device/a67b75ca96b26f44/event/up/#"
        //     )
        // },
    },
    data: () => ({
        active_panel: "",
    }),
}
</script>
