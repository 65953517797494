<template>
  <v-app
    v-if="
      this.$route.name === '401' ||
      this.$route.name === '403' ||
      this.$route.name === '404' ||
      this.$route.name === '500'
    ">
    <v-main class="error-page">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else-if="this.$route.name === 'Login'">
    <v-main class="login-page">
      <v-container fluid>
        <v-offline @detected-condition="handleConnectivityChange"> </v-offline>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <AppBar :menus="menus" />
    <v-main style="background-color: #e4f5e5">
      <v-container fluid>
        <v-offline @detected-condition="handleConnectivityChange"> </v-offline>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment";
import AppBar from "./components/AppBar.vue";
import { VOffline } from "v-offline";

export default {
  name: "App",
  components: {
    AppBar,
    "v-offline": VOffline,
  },
  mounted() {
    document.title = "Smart Farming";
  },
  data: () => ({
    menus: [],
  }),
  methods: {
    handleConnectivityChange(status) {
      this.$store.commit("setInternetConnection", status);
    },
    checkPathAuthentication() {
      let auth = false;
      this.menus.map(async (main) => {
        if (main.subs.length > 0) {
          main.subs.map((sub) => {
            if (sub.link == this.$route.path) {
              auth = true;
            }
          });
        }
        if (main.link == this.$route.path) {
          auth = true;
        }
      });
      if (!auth) {
        window.location.href = "/403";
      }
    },
  },
  async created() {
    if (
      this.$route.name !== "Login" &&
      this.$route.name !== "401" &&
      this.$route.name !== "403" &&
      this.$route.name !== "404"
    ) {
      this.menus = await this.globalCheckToken();
    }
    if (
      this.$route.path !== "/404" &&
      this.$route.path !== "/401" &&
      this.$route.path !== "/403" &&
      this.$route.path !== "/"
    ) {
      await this.checkPathAuthentication();
    }
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },
    notify: function (data) {
      console.log("notify", data);
      let obj = {
        status: "new",
        icon: "mdi-alert",
        title: data.message,
        subtitle: data.message,
        time: data.timestamp,
      };
      this.$store.commit("setNotifications", obj);
    },
    updateSensor: function (data) {
      // console.log("update sensor", data)
      let obj = {
        temperature: parseInt(data.data.temperature),
        humidity: parseInt(data.data.humidity),
        soil_moisture: parseInt(data.data.soil_moisture),
        soil_ec: parseInt(data.data.soil_ec),
        dev_eui: data.dev_eui,
        last_updated: moment(data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        animated: "animated",
        disabled: false,
      };
      this.$store.commit("setFarmSensors", obj);

      setTimeout(() => {
        obj.animated = "";
        this.$store.commit("setFarmSensors", obj);
      }, 5000);
    },
    updateControl: function (data) {
      let obj = {
        device_name: parseInt(data.device_name),
        dev_eui: data.dev_eui,
        last_updated: moment(data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        disabled: false,
      };
      this.$store.commit("setFarmSensors", obj);
    },
    updateCommandIoTSuccess: function (data) {
      let obj = {
        farm_name: data.farm_name,
      };
      const farm_name = obj.farm_name;
      console.log(farm_name, "test data");
      const currentCallOptions = {
        durations: {
          success: 4500,
        },
        labels: {
          success: "สั่งงาน IoT สำเร็จแล้ว",
        },
        icons: {
          enabled: true,
          prefix:
            "<i class='v-icon ml-4 mdi mdi-sticker-check-outline' style='font-size: 32px;'>",
          suffix: "</i>",
          success: "",
        },
      };
      this.$awn.success(
        "การสั่งการระบบ IoT :" + farm_name + " สำเร็จแล้ว",
        currentCallOptions
      );
    },
    disconnect: function () {
      // console.log("socket disconnected")
    },
  },
};
</script>

<style scoped>
.login-page {
  align-items: center;
  display: flex;
  height: 50vh !important;
  background: url(https://wallpaper.dog/large/10882077.jpg) no-repeat center
    center;
  /* backdrop-filter: blur(100px); */
  background-size: cover;
}

.error-page {
  align-items: center;
  display: flex;
  height: 100vh !important;
  background-color: #ececec;
  background-size: cover;
  justify-content: center;
  color: black;
}
</style>
