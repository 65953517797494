<template>
  <div>
    <v-container fluid v-if="!loaddata">
      <v-row>
        <v-col cols="6">
          <h3 :style="'font-size: 16px !important'">
            <v-icon class="mr-1">mdi-cog</v-icon>
            {{ $t("settings.title") }} > Plant
          </h3>
        </v-col>
        <v-col cols="6" align="end">
          <v-btn
            small
            dark
            rounded
            elevation="2"
            color="green darken-3"
            @click="newData">
            <v-icon>mdi-plus</v-icon>
            <div>เพิ่มข้อมูลใหม่</div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            id="mytable"
            :headers="headers"
            :items="plants"
            :items-per-page="10"
            class="elevation-1">
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.category`]="{ item }">
              {{ item.category.category_name }}
            </template>
            <template v-slot:[`item.growth`]="{ item }">
              {{ item.growth_min }} - {{ item.growth_max }}
            </template>
            <template v-slot:[`item.temp`]="{ item }">
              {{ item.temp_min }} - {{ item.temp_max }}
            </template>
            <template v-slot:[`item.humid`]="{ item }">
              {{ item.humid_min }} - {{ item.humid_max }}
            </template>
            <template v-slot:[`item.moist`]="{ item }">
              {{ item.moist_min }} - {{ item.moist_max }}
            </template>
            <template v-slot:[`item.ec`]="{ item }">
              {{ item.soilec_min }} - {{ item.soilec_max }}
            </template>
            <template v-slot:[`item.ph`]="{ item }">
              {{ item.ph_min }} - {{ item.ph_max }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editData(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteData(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      right
      shaped
      bottom
      outlined
      height="5%"
      color="success">
      {{ text }}
    </v-snackbar>
    <v-dialog v-model="confirm_form" width="300" height="100">
      <v-card>
        <v-card-title class="white--text green darken-3">
          คุณต้องการลบข้อมูลหรือไม่?
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn rounded color="grey lighten-5" @click="reset_confirm">
            ยกเลิก
          </v-btn>
          <v-btn
            rounded
            color="white--text green darken-3"
            @click="form_type == 'delete' ? deleteSelectData() : saveNewData()">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_form" width="1100" @click:outside="reset">
      <v-card>
        <v-card-title class="white--text green darken-3">
          {{ form_type == "edit" ? "แก้ไขข้อมูลพืช" : "เพิ่มข้อมูลพืชใหม่" }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-3">
              <v-col cols="12" md="4" align="right" class="mini-text">
                <v-combobox
                  v-model="form.category"
                  :items="categories"
                  outlined
                  dense
                  light
                  rounded
                  label="หมวดหมู่"
                  item-text="category_name"></v-combobox>
              </v-col>
              <v-col cols="12" md="4" align="right" class="mini-text">
                <v-text-field
                  v-model="form.plant_name"
                  outlined
                  dense
                  light
                  rounded
                  label="ชื่อพืช"></v-text-field>
              </v-col>
              <v-col cols="12" md="4" align="right" class="mini-text">
                <v-text-field
                  v-model="form.season"
                  outlined
                  dense
                  light
                  rounded
                  label="ฤดูกาลที่ควรปลูก"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="font-weight-bold">
                ระยะเวลาในการเจริญเติบโตของพืช
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.growth_min"
                  outlined
                  dense
                  light
                  rounded
                  label="ต่ำสุด"
                  suffix="วัน"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.growth_max"
                  outlined
                  dense
                  light
                  rounded
                  label="สูงสุด"
                  suffix="วัน"></v-text-field>
              </v-col>
              <v-col cols="12" class="font-weight-bold"> อุณหภูมิ</v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.temp_min"
                  outlined
                  dense
                  light
                  rounded
                  label="ต่ำสุด"
                  suffix="องศา"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.temp_max"
                  outlined
                  dense
                  light
                  rounded
                  label="สูงสุด"
                  suffix="องศา"></v-text-field>
              </v-col>
              <v-col cols="12" class="font-weight-bold">
                ความชื้นในอากาศ
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.humid_min"
                  outlined
                  dense
                  light
                  rounded
                  label="ต่ำสุด "
                  suffix="%"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.humid_max"
                  outlined
                  dense
                  light
                  rounded
                  label="สูงสุด "
                  suffix="%"></v-text-field>
              </v-col>
              <v-col cols="12" class="font-weight-bold"> ความชื้นในดิน</v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.moist_min"
                  outlined
                  dense
                  light
                  rounded
                  label="ต่ำสุด "
                  suffix="%"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.moist_max"
                  outlined
                  dense
                  light
                  rounded
                  label="สูงสุด "
                  suffix="%"></v-text-field>
              </v-col>
              <v-col cols="12" class="font-weight-bold"> ค่านำไฟฟ้าในดิน</v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.soilec_min"
                  outlined
                  dense
                  light
                  rounded
                  label="ต่ำสุด"
                  suffix="ms/cm"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.soilec_max"
                  outlined
                  dense
                  light
                  rounded
                  label="สูงสุด"
                  suffix="ms/cm"></v-text-field>
              </v-col>
              <v-col cols="12" class="font-weight-bold"> pH ในดิน </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.ph_min"
                  outlined
                  dense
                  light
                  rounded
                  label="ต่ำสุด"
                  suffix="%"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" align="right" class="mini-text">
                <v-text-field
                  v-model="form.ph_max"
                  outlined
                  dense
                  light
                  rounded
                  label="สูงสุด"
                  suffix="%"></v-text-field>
              </v-col>
              <v-col cols="12" class="mini-text pt-0 mt-0">
                <v-card-text class="pt-0 mt-0">
                  <v-container fluid class="px-0">
                    <v-row align="center">
                      <v-col cols="6">
                        <h3>ตั้งค่าระบบการทำงานอัตโนมัติ</h3>
                      </v-col>
                      <v-col cols="6" align="right">
                        <v-btn
                          @click="newCondition()"
                          x-small
                          rounded
                          color="green darken-3  white--text"
                          ><v-icon left small>mdi-plus</v-icon
                          >เพิ่มเงื่อนไขใหม่</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr class="bg-grey-main">
                          <th class="text-center">เงื่อนไขการทำงาน</th>
                          <th class="text-center">
                            ระยะเวลาทำงานของระบบ (นาที)
                          </th>
                          <th class="text-center">ระบบที่เปิดทำงาน</th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, idx) in form.plants_settings"
                          :key="idx">
                          <td>
                            <span v-if="item.condition == 'time'"
                              >เวลา
                              {{ item.variable }}
                            </span>
                            <span v-if="item.condition == 'temperature'"
                              >อุณหภูมิมากกว่า
                              {{ item.variable }}
                              องศา</span
                            >
                            <span v-if="item.condition == 'soil_moisture'"
                              >ค่าความชื้นในดินน้อยกว่า
                              {{ item.variable }}
                              %</span
                            >
                            <span v-if="item.condition == 'humidity'"
                              >ค่าความชื้นในอากาศน้อยกว่า
                              {{ item.variable }}
                              %</span
                            >
                          </td>
                          <td class="text-center">
                            {{ item.timeout }}
                          </td>
                          <td>
                            {{ item.system.join(", ") }}
                          </td>
                          <td>
                            <v-icon
                              small
                              class="mr-2"
                              @click="editCondition(item, idx)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteCondition(idx)">
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small rounded color="grey lighten-5" @click="reset">
            ยกเลิก
          </v-btn>

          <v-btn
            small
            rounded
            color="white--text bg-blue-main"
            :disabled="plantConditionRule"
            @click="form_type == 'edit' ? saveEditData() : saveNewData()">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_condition" width="800">
      <v-card>
        <v-card-title class="white--text green darken-3">
          เพิ่มเงื่อนไขการทำงานอัตโนมัติใหม่
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-radio-group v-model="new_condition.condition">
                  <v-radio
                    label="ทำงานตามช่วงเวลา"
                    value="time"
                    color="green darken-3"></v-radio>
                  <v-radio
                    label="เมื่ออุณหภูมิ สูงกว่า"
                    value="temperature"
                    color="green darken-3"></v-radio>
                  <v-radio
                    label="เมื่อ % ความชื้นในดิน ต่ำกว่า"
                    value="soil_moisture"
                    color="green darken-3"></v-radio>
                  <v-radio
                    label="เมื่อ % ความชื้นในอากาศ ต่ำกว่า"
                    value="humidity"
                    color="green darken-3"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" v-if="new_condition.condition">
                <v-row>
                  <v-col class="pb-0" cols="12">
                    <!-- <v-dialog
                                            v-if="
                                                new_condition.condition ==
                                                'time'
                                            "
                                            ref="dialog"
                                            v-model="menu"
                                            :return-value.sync="time"
                                            persistent
                                            width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    dense
                                                    v-model="
                                                        new_condition.variable
                                                    "
                                                    label="เริ่มทำงานระบบเวลา"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    color="green darken-3"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="menu"
                                                color="green darken-3"
                                                v-model="new_condition.variable"
                                                full-width
                                                format="24hr"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="secondary"
                                                    @click="menu = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="green darken-3"
                                                    @click="
                                                        $refs.dialog.save(
                                                            new_condition.variable
                                                        )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog> -->
                    <h4 v-if="new_condition.condition == 'time'">
                      เวลาเริ่มทำงานระบบ
                      <date-picker
                        v-model="new_condition.variable"
                        type="time"
                        value-type="HH:mm"
                        format="HH:mm"
                        placeholder="กรุณาเลือกเวลา"
                        confirm
                        :style="'padding-left:20px !important;width:50% !important'">
                      </date-picker>
                    </h4>
                    <v-text-field
                      v-else
                      dense
                      color="green darken-3"
                      v-model="new_condition.variable"
                      label="ค่าที่ใช้ดำเนินการตามเงื่อนไข"
                      prepend-icon="mdi-tooltip-text-outline"></v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12">
                    <v-text-field
                      dense
                      color="green darken-3"
                      v-model="new_condition.timeout"
                      label="ระยะเวลาทำงานของระบบ"
                      prepend-icon="mdi-timer-outline"
                      suffix="นาที"
                      type="number"
                      :rules="valDuration"></v-text-field>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="6">
                    <v-checkbox
                      v-model="new_condition.system[0]"
                      color="green darken-3"
                      label="ระบบสปริงเกอร์"
                      value="SP"></v-checkbox>
                  </v-col>
                  <v-col class="pb-0" cols="12" md="6">
                    <v-checkbox
                      v-model="new_condition.system[1]"
                      color="green darken-3"
                      label="ระบบพ่นหมอก"
                      value="FG"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            rounded
            color="white--text ml-4 bg-blue-main"
            :disabled="newConditionRule"
            @click="
              dialog_condition_type == 'edit'
                ? saveEditCondition()
                : saveNewCondition()
            "
            ><v-icon left small>mdi-content-save</v-icon>บันทึก</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SettingPlant",
  components: {},
  mounted() {
    this.getData();
  },
  data: () => ({
    loaddata: false,
    valDuration: [(v) => v > 0 || "ระยะเวลาทำงานของระบบต้องมากกว่า 0 นาที"],
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "หมวดหมู่", value: "category" },
      { text: "ชื่อพืช", value: "plant_name" },
      { text: "ฤดูกาล", value: "season" },
      { text: "ระยะเวลาเติบโต (วัน)", value: "growth" },
      { text: "อุณหภูมิ (องศา)", value: "temp" },
      { text: "ความชื้นในอากาศ (%)", value: "humid" },
      { text: "ความชื้นในดิน (%)", value: "moist" },
      { text: "ค่า EC (ms/cm)", value: "ec" },
      { text: "ค่า pH", value: "ph" },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    categories: [],
    plants: [],
    dialog_form: false,
    snackbar: false,
    text: "Successfully Update!",
    timeout: 3000,
    form_type: "",
    form: {
      category: "",
      plant_name: "",
      temp_min: "",
      temp_max: "",
      humid_min: "",
      humid_max: "",
      moist_min: "",
      moist_max: "",
      plants_settings: [],
    },
    default_conditions: [
      {
        type: "schedule",
        condition: "time",
        veriable: "08:00",
        timeout: "20",
        system: ["SP", "FG"],
        status: "Active",
      },
      {
        type: "schedule",
        condition: "time",
        veriable: "16:00",
        timeout: "20",
        system: ["SP", "FG"],
        status: "Active",
      },
      {
        type: "condition",
        condition: "temperature",
        veriable: "36",
        timeout: "20",
        system: ["SP", "FG"],
        status: "Active",
      },
      {
        type: "condition",
        condition: "soil_moisture",
        veriable: "50",
        timeout: "20",
        system: ["SP", "FG"],
        status: "Active",
      },
      {
        type: "condition",
        condition: "humidity",
        veriable: "59",
        timeout: "20",
        system: ["SP", "FG"],
        status: "Active",
      },
    ],
    dialog_condition: false,
    dialog_condition_type: "",
    new_condition: {
      type: "",
      condition: "",
      veriable: "",
      timeout: "",
      system: [],
      status: "",
    },
    menu: false,
  }),
  computed: {
    newConditionRule() {
      return !(
        this.new_condition.condition &&
        this.new_condition.timeout &&
        (this.new_condition.time || this.new_condition.variable) &&
        this.new_condition.system.length != ""
      );
    },
    plantConditionRule() {
      return !(
        this.form.category &&
        this.form.plant_name &&
        this.form.temp_min &&
        this.form.temp_max &&
        this.form.humid_min &&
        this.form.humid_max != ""
      );
    },
  },
  methods: {
    async getData() {
      this.loaddata = true;
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_HOST}/setting/plants`,
        this.bearerToken
      );
      this.categories = [...data.category];
      this.plants = [...data.plant];
      this.loaddata = false;
    },
    newData() {
      this.dialog_form = true;
      this.form_type = "new";
    },
    async saveNewData() {
      this.form.plants_settings.forEach(function (each) {
        each.system = each.system.join("");
      });
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_HOST}/setting/plants`,
        this.form,
        this.bearerToken
      );
      this.reset();
      this.getData();
      this.saveNewDataModal();
    },
    async editData(editData) {
      this.form_type = "edit";

      const { data } = await axios.get(
        `${process.env.VUE_APP_API_HOST}/setting/plants/${editData.plant_id}`,
        this.bearerToken
      );
      // if (typeof data.plants_settings == "object") {
      //     let obj = data.plants_settings
      //     data.plants_settings = []
      //     if (obj.p_setting_id != null) {
      //         data.plants_settings.push(obj)
      //     }
      // }
      data.plants_settings.forEach(function (each) {
        let sys_array = [];
        if (each.system.indexOf("SP") > -1) {
          sys_array[0] = "ระบบสปริงเกอร์";
        }
        if (each.system.indexOf("FG") > -1) {
          sys_array[1] = "ระบบพ่นหมอก";
        }
        each.system = sys_array;
      });
      this.form = { ...data };
      this.dialog_form = true;
    },
    async saveEditData() {
      this.form.plants_settings.forEach(function (each) {
        each.system = each.system.join("");
      });

      const { data } = await axios.put(
        `${process.env.VUE_APP_API_HOST}/setting/plants/${this.form.plant_id}`,
        this.form,
        this.bearerToken
      );
      this.getData();
      this.reset();
      this.editDataModal();
    },
    reset() {
      this.dialog_form = false;
      this.form = {
        category: "",
        plant_name: "",
        temp_min: "",
        temp_max: "",
        humid_min: "",
        humid_max: "",
        moist_min: "",
        moist_max: "",
        plants_settings: [],
      };
    },
    newCondition() {
      this.dialog_condition = true;
      this.dialog_condition_type = "new";
    },
    saveNewCondition() {
      this.form.plants_settings.push(this.new_condition);
      this.new_condition = {
        condition: "",
        time: "",
        variable: "",
        timeout: "",
        system: [],
        status: "Active",
      };
      this.dialog_condition = false;
    },
    editCondition(item, idx) {
      this.dialog_condition_type = "edit";
      this.new_condition = { ...item };
      this.new_condition.idx = idx;
      this.dialog_condition = true;
    },
    saveEditCondition() {
      this.form.plants_settings.splice(
        this.new_condition.idx,
        1,
        this.new_condition
      );

      this.new_condition = {
        condition: "",
        time: "",
        variable: "",
        timeout: "",
        system: [],
        status: "Active",
      };
      this.dialog_condition = false;
      this.editDataModal();
    },
    deleteCondition(idx) {
      this.form.plants_settings.splice(idx, 1);
    },
    deleteData(data) {
      this.confirm_form = true;
      this.form_type = "delete";
      this.form = { ...data };
    },
    async deleteSelectData() {
      const { data } = await axios.delete(
        `${process.env.VUE_APP_API_HOST}/setting/plants/${this.form.plant_id}`,
        this.bearerToken
      );
      this.confirm_form = false;
      this.getData();
      this.deleteDataModal();
    },
    reset_confirm() {
      this.confirm_form = false;
      this.form = {
        category: "",
        plant_name: "",
        temp_min: "",
        temp_max: "",
        humid_min: "",
        humid_max: "",
        moist_min: "",
        moist_max: "",
        plants_settings: [],
      };
    },
    async editDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async deleteDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async saveNewDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<style>
#mytable .v-data-table__wrapper table thead {
  background: rgb(220, 220, 220) !important;
}
</style>
