<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="6">
                    <h3>
                        <v-icon class="mr-4">mdi-text-box-outline</v-icon>
                        {{ $t("reports.title") }} > Glasshouse-history
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-container fluid>
                        <v-row>
                            <v-col
                                cols="3"
                                v-for="(farm, idx) in farms"
                                :key="idx"
                            >
                                <HistoryGlasshouse :farm="farm" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import HistoryGlasshouse from "@/components/HistoryGlasshouse.vue"
export default {
    name: "GlasshouseHistory",
    components: { HistoryGlasshouse },
    mounted() {},
    data: () => ({
        farms: [
            {
                farm_id: 1,
                farm_name: "โรงเรือนที่ 01",
                farming_status: "Farming",
                histories: [
                    {
                        start_date: "2022-02-02",
                        end_date: "2022-02-03",
                        plant_name: "ผักสลัด",
                    },
                ],
            },
            {
                farm_id: 2,
                farm_name: "โรงเรือนที่ 02",
                farming_status: "Empty",
                histories: [
                    {
                        start_date: "2022-02-02",
                        end_date: "2022-02-03",
                        plant_name: "ผักสลัด",
                    },
                    {
                        start_date: "2022-02-02",
                        end_date: "2022-02-03",
                        plant_name: "ผักสลัด",
                    },
                ],
            },
            {
                farm_id: 2,
                farm_name: "โรงเรือนที่ 04",
                farming_status: "Empty",
                histories: [],
            },
        ],
    }),
    methods: {},
    beforeCreate() {},
}
</script>

<style scoped></style>
