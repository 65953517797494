<template>
  <v-card
    :class="
      'pa-1 ' +
      (!sensor.disabled || !sensor_disabled ? '' : disabled_class) +
      sensor.animated
    "
    elevation="0"
    rounded="lg">
    <v-card-title class="pa-1 justify-space-between">
      <!-- <v-icon
                v-if="!sensor.disabled || !sensor_disabled"
                class="mr-2 mt-1"
                color="green darken-3"
                x-small
                >mdi-circle</v-icon
            >
            <img v-else width="5%" src="../assets/dot-pulse.svg" /> -->
      {{ farm.farm.farm_name }}
      <!-- <v-spacer class="d-none d-md-flex"></v-spacer> -->
      <v-chip
        v-if="visibleActions"
        small
        color="green darken-3 white--text font-weight-bold">
        <v-icon class="mr-2" x-small>mdi-sprout</v-icon>
        {{ getAmountDays(farm.start_date) }}
        วัน
      </v-chip>
      <v-chip v-else small color="grey white--text font-weight-bold">
        โรงเรือนว่าง
      </v-chip>
      <!-- <v-spacer class="d-none d-md-flex"></v-spacer> -->
      <span class="caption">
        <v-icon small>mdi-clock-outline</v-icon>
        ล่าสุด:
        {{
          (sensor.last_updated || farm.updated_at || "###### ##:##")
            | moment("DD MMM YY HH:mm")
        }}
        <!-- Last seen {{ farm.updated_at | moment("from", "now") }} -->
      </span>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-row class="d-sm-flex d-md-none">
        <v-col cols="4" class="pa-0 pr-1">
          <v-list
            rounded
            :class="
              'h-100 ' +
              (!sensor.disabled || !sensor_disabled
                ? 'bg-temp '
                : 'grey lighten-3 ')
            ">
            <v-list-item class="pa-0 ma-0">
              <v-list-item-icon class="px-0 mx-0">
                <v-icon large v-text="'mdi-thermometer'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content align="right">
                <v-list-item-title>
                  <h2>
                    {{ sensor.temperature || farm.temperature }}
                    <span :style="'font-size:55%'">&deg;C</span>
                  </h2>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0 ma-0">
              <v-list-item-content class="pa-0 ma-0" align="center">
                <v-list-item-subtitle class="text-wrap text-caption">
                  {{ $t("sys.temp") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="4" class="pa-0 pr-1">
          <v-list
            rounded
            :class="
              'h-100 ' +
              (!sensor.disabled || !sensor_disabled
                ? 'bg-humid '
                : 'grey lighten-3 ')
            ">
            <v-list-item class="pa-0 ma-0">
              <v-list-item-icon class="px-0 mx-0">
                <v-icon large v-text="'mdi-water'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content align="right">
                <v-list-item-title>
                  <h2>
                    {{ sensor.humidity || farm.humidity }}
                    <span :style="'font-size:55%'">%</span>
                  </h2>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0 ma-0">
              <v-list-item-content class="pa-0 ma-0" align="center">
                <v-list-item-subtitle class="text-wrap text-caption">
                  {{ $t("sys.humid") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="4" class="pa-0 pr-1">
          <v-list
            rounded
            :class="
              'h-100 ' +
              (!sensor.disabled || !sensor_disabled
                ? 'bg-soil-moist '
                : 'grey lighten-3 ')
            ">
            <v-list-item class="pa-0 ma-0">
              <v-list-item-icon class="px-0 mx-0">
                <v-icon large v-text="'mdi-water'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content align="right">
                <v-list-item-title>
                  <h2>
                    {{ sensor.soil_moisture || farm.soil_moisture }}
                    <span :style="'font-size:55%'">%</span>
                  </h2>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0 ma-0">
              <v-list-item-content class="pa-0 ma-0" align="center">
                <v-list-item-subtitle class="text-wrap text-caption">
                  {{ $t("sys.moist") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="d-none d-md-flex">
        <v-col xs="6" sm="4" class="pa-0 pr-1 offset-3 offset-sm-0">
          <v-list color="transparent" rounded>
            <v-list-item class="px-0 mx-0">
              <v-list-item-icon class="px-0 ma-0">
                <vue-thermometer
                  :options="tempChartOptions"
                  :min="-0"
                  :max="50"
                  :value="sensor.temperature || farm.temperature" />
              </v-list-item-icon>
              <v-list-item-content align="center" class="pr-12">
                <v-list-item-title>
                  <h2>
                    {{ sensor.temperature || farm.temperature
                    }}<b class="ml-1" :style="'font-size: 60%'">&deg;C</b>
                  </h2>
                </v-list-item-title>
                <p class="mb-0 pb-0 gaugetitle">
                  {{ $t("sys.temp") }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col xs="6" sm="4" lg="4" class="pa-0 pr-1" align="center">
          <gauge
            class="pt-3 py-0 gaugetitle"
            :heading="$t('sys.humid')"
            :style="'font-size: calc(11px + 0.05vw);'"
            :min="0"
            :max="100"
            :headingLevel="4"
            unit="%"
            activeFill="#DE6E4B"
            inactiveFill="#ededed"
            :value="sensor.humidity || farm.humidity"
            :thickness="50"
            fontSize="1.5em" />
        </v-col>
        <v-col xs="6" sm="4" class="pa-0 pr-1" align="center">
          <gauge
            class="pt-3 py-0 gaugetitle"
            :style="'font-size: calc(11px + 0.05vw);'"
            :heading="$t('sys.moist')"
            :min="0"
            :max="100"
            :headingLevel="4"
            unit="%"
            activeFill="#7A6563"
            inactiveFill="#ededed"
            :value="sensor.soil_moisture || farm.soil_moisture"
            :thickness="50"
            fontSize="1.5em" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-1"></v-divider>
    <!-- v-if="visibleActions"  -->
    <v-card-actions class="space-beteen">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row class="justify-center">
        <v-col v-if="visibleActions" cols="6" class="pa-0 pr-1">
          <v-btn
            class="transparent"
            @click="showSub(farm)"
            block
            elevation="0"
            color="white">
            <v-icon color="grey" class="mr-3">mdi-magnify</v-icon>
            รายละเอียด
          </v-btn>
        </v-col>
        <v-col cols="6" class="pa-0 pr-1">
          <v-btn
            class="transparent"
            @click="showControl(farm)"
            block
            elevation="0"
            color="white">
            <v-icon
              v-if="!control.disabled || !control_disabled"
              class="mr-2 mt-1 mb-1"
              color="green darken-3"
              x-small
              >mdi-circle</v-icon
            >
            <img v-else width="13%" src="../assets/dot-pulse.svg" />
            <v-icon color="grey" class="mr-3">mdi-dip-switch</v-icon>
            สั่งงาน
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <!-- <v-card-actions v-else>
            <v-row>
                <v-col cols="12" class="pa-0 pr-1">
                    <v-btn
                        class="transparent"
                        block
                        elevation="0"
                        color="white"
                        plain
                    >
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions> -->
  </v-card>
</template>

<script>
// import axios from "axios"
import moment from "moment";
export default {
  name: "Glasshouse",
  props: ["farm", "visibleSubtitle"],
  beforeMount() {
    const sensor = this.farm.farm.devices.filter(
      (each) => each.device_type == "Sensor"
    );
    if (this.checkLatest(sensor[0].updated_at) > 0) {
      this.farm.temperature = 0;
      this.farm.humidity = 0;
      this.farm.soil_moisture = 0;
      this.sensor_disabled = true;
    } else {
      this.farm.temperature = parseInt(sensor[0].temperature);
      this.farm.humidity = parseInt(sensor[0].humidity);
      this.farm.soil_moisture = parseInt(sensor[0].soil_moisture);
      this.farm.updated_at = sensor[0].updated_at;
    }
    this.sensor_dev_eui = sensor[0].dev_eui;

    const control = this.farm.farm.devices.filter(
      (each) => each.device_type == "Control"
    );

    if (this.checkLatest(control[0].updated_at) > 0) {
      this.control_disabled = true;
    }
    this.control_dev_eui = control[0].dev_eui;
  },
  mounted() {
    if (this.farm.farming_id == undefined) {
      this.visibleActions = false;
    }
  },
  computed: {
    sensor() {
      return this.$store.getters.farmSensors(this.sensor_dev_eui);
    },
    control() {
      return this.$store.getters.farmSensors(this.control_dev_eui);
    },
  },
  methods: {
    getAmountDays: function (start) {
      let a = moment();
      let b = moment(start);
      let amount = a.diff(b, "days");
      return amount;
    },
    checkLatest: function (updated_at) {
      let amount = 3;
      if (updated_at) {
        let a = moment();
        let b = moment(updated_at);
        amount = a.diff(b, "hours");
      }
      return amount;
    },
    showSub: function (farm) {
      this.overlay = !this.overlay;
      this.$emit("showSub", farm);
    },
    showControl: function (farm) {
      this.$emit("showControl", farm);
    },
    getSensorsData: function (type, dev_eui, querydata) {
      const data = this.$store.getters.farmSensors(dev_eui);
      let returndata = "-";
      // alert(data.temperature)
      if (data.temperature != "") {
        if (this.checkLatest(data.last_updated) > 1) {
          returndata = 0;
        } else {
          if (type == "temperature") {
            returndata = parseInt(data.temperature);
          }
          if (type == "humidity") {
            returndata = parseInt(data.humidity);
          }
          if (type == "soil_moisture") {
            returndata = parseInt(data.soil_moisture);
          }
          if (type == "last_updated") {
            returndata = data.last_updated;
          }
          if (type == "animated") {
            returndata = data.animated;
          }
        }
      }
      if (querydata != null) {
        if (
          type == "temperature" ||
          type == "humidity" ||
          type == "soil_moisture"
        ) {
          returndata = parseInt(querydata);
        }
        if (type == "last_updated" || type == "animated") {
          returndata = querydata;
        }
      }
      return returndata;
    },
    check_sensor: function () {
      return this.$store.getters.checkSensor;
    },
  },
  data: () => ({
    sensor_dev_eui: "",
    sensor_disabled: false,
    control_disabled: false,
    disabled_class: "grey lighten-2 ",
    tempData: 36,
    overlay: false,
    tempChartOptions: {
      text: {
        color: "black",
        fontSize: 10,
        textAdjustmentY: 2,
        fontFamily: "Sarabun",
        textEnabled: false,
      },
      thermo: {
        color: "#7FD1B9",
        backgroundColor: "#ededed",
        frameColor: "#FFFFFF",
        ticks: 6,
        ticksEnabled: false,
        tickColor: "black",
        tickWidth: "1",
      },
      layout: {
        height: 125,
        width: 60,
      },
    },
    exampleValue: 60,
    visibleActions: true,
  }),
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 2000);
    },
  },
};
</script>
<style scoped lang="css">
.v-list-item {
  min-height: 0px;
}
</style>
