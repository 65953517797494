import Vue from "vue"
import VueRouter from "vue-router"

import Dashboard from "../views/Dashboard.vue"
import Farm from "../views/Farm.vue"
import Unauthorized from "../views/status/401.vue"
import Forbidden from "../views/status/403.vue"
import PageNotFound from "../views/status/404.vue"
import InternalError from "../views/status/500.vue"

import SettingFarm from "../views/Setting/Farm.vue"
import SettingDevice from "../views/Setting/Device.vue"
import SettingPlant from "../views/Setting/Plant.vue"
import SettingUser from "../views/Setting/User.vue"


import ReportGlasshouseHistory from "../views/Report/GlasshouseHistory.vue"
import ReportGlasshouseIncome from "../views/Report/GlasshouseIncome.vue"

import Login from "../views/Login.vue"
import Calendar from "../views/Calendar.vue"
import store from "../store/index"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        alias: "/Login",
        name: "Login",
        component: Login,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/Farm",
        name: "Farm",
        component: Farm,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Calendar",
        name: "Calendar",
        component: Calendar,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Setting/Device",
        name: "SettingDevice",
        component: SettingDevice,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Setting/Farm",
        name: "SettingFarm",
        component: SettingFarm,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Setting/Plant",
        name: "SettingPlant",
        component: SettingPlant,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Setting/User",
        name: "SettingUser",
        component: SettingUser,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Report/Glasshouse-history",
        name: "ReportGlasshouseHistory",
        component: ReportGlasshouseHistory,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/Report/Glasshouse-income",
        name: "ReportGlasshouseIncome",
        component: ReportGlasshouseIncome,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/401",
        name: "401",
        component: Unauthorized,
    },
    {
        path: "/403",
        name: "403",
        component: Forbidden,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/404",
        name: "404",
        component: PageNotFound,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/500",
        name: "500",
        component: InternalError,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "*",
        redirect: "/404",
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (!store.getters.isLoggedIn) {
            next({ name: "Login" })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
