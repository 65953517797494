<template>
  <div>
    <v-navigation-drawer v-model="drawer" app color="green darken-3">
      <!--  -->
      <!-- src="https://www.pacbiztimes.com/wp-content/uploads/2021/12/Glass-House.jpg" -->
      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-title class="white--text text-h6 font-weight-regular">
            <div class="my-2">Smart Farming</div>
          </v-list-item-title>
          <v-list-item-subtitle class="white--text font-weight-thin">
            V 1.2.4
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- MENU -->
      <v-list dense nav dark>
        <div v-for="item in menus" :key="item.title">
          <v-list-item
            v-if="item.subs.length == 0"
            link
            :to="item.link"
            color="white">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.lang_var) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else color="white">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t(item.lang_var) }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="(sub, i) in item.subs"
              :key="i"
              link
              sub-group
              :to="sub.link"
              color="white"
              dark>
              <v-list-item-icon>
                <v-icon>{{ sub.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(sub.lang_var) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
        <v-list-item link @click="logout()" color="white">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> ออกจากระบบ </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- <v-row class="mt-3 mb-3">
                <v-col class="text-center">
                    <v-btn class="px-3" @click="changeLang()" rounded x-small>
                        <v-icon small class="mr-2">mdi-web</v-icon>
                        {{ $t("sys.lang_change") }}
                    </v-btn>
                </v-col>
            </v-row> -->
    </v-navigation-drawer>
    <v-app-bar dense app light elevation="4">
      <v-app-bar-nav-icon
        color="green darken-3"
        @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <Weather />

      <!-- <v-menu transition="slide-y-transition" left offset-y rounded="md">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-1" icon light v-bind="attrs" v-on="on">
                        <v-icon color="green darken-3">mdi-text-box</v-icon>
                    </v-btn>
                </template>
                <v-list two-line>
                    <v-list-item-group>
                        <template v-for="(item, index) in logs">
                            <v-list-item :key="item.title">
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="item.title"
                                    ></v-list-item-title>

                                    <v-list-item-subtitle
                                        v-text="item.subtitle"
                                    ></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text
                                        v-text="item.time"
                                    ></v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider
                                v-if="index < logs.length - 1"
                                :key="index"
                            ></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-menu> -->

      <Notifications />
    </v-app-bar>
  </div>
</template>

<script>
import axios from "axios";
import Weather from "@/components/Weather.vue";
import Notifications from "@/components/Notifications.vue";

export default {
  name: "AppBar",
  components: { Weather, Notifications },
  props: ["menus"],
  methods: {
    changeDrawer() {
      this.$emit("clicked", !this.drawer);
    },
    changeLang() {
      this.$i18n.locale = this.$i18n.locale == "en" ? "th" : "en";
    },
    async logout() {
      this.$swal({
        title: "ต้องการออกจากระบบใช่หรือไม่ ?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "ใช่",
        confirmButtonColor: "#43a047",
        cancelButtonText: "ไม่ใช่",
        cancelButtonColor: "#E0E0E0",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await axios.get(
            `${process.env.VUE_APP_API_HOST}/auth/logout`,
            this.bearerToken
          );
          await this.$swal({
            title: "ออกจากระบบเรียบร้อยแล้ว!",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
          });
          localStorage.token_smf = "";
          localStorage.user_lang = "en";
          localStorage.user_role = "";
          this.$store.commit("setLogInStatus", false);
          window.location.href = "/";
        }
      });
    },
  },
  mounted() {},
  data: () => ({
    logs: [],
    drawer: null,
  }),
};
</script>
