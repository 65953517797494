<template>
    <div>
        <v-menu
            @input="onMenuToggle"
            transition="slide-y-transition"
            left
            offset-y
            rounded="md"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-badge :color="color" dot bottom overlap>
                    <v-btn
                        small
                        class="mr-1"
                        icon
                        light
                        v-bind="attrs"
                        v-on="on"
                        @click="reset"
                    >
                        <v-icon :class="{ shake: disabled }" :color="iconColor"
                            >mdi-bell</v-icon
                        >
                    </v-btn>
                </v-badge>
            </template>
            <template v-if="$store.getters.notifications.length == 0">
                <v-list class="v-list-scrollable pa-0" dense>
                    <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0 text-center">
                            <v-lottie-player
                                loop
                                name="nocontent"
                                :animationData="
                                    require('../assets/lottie/no_content.json')
                                "
                            />
                            <v-list-item-title
                                class="pb-4 text-subtitle-2 font-weight-bold"
                            >
                                ไม่มีรายการแจ้งเตือนในขณะนี้!
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <template v-else>
                <v-list class="v-list-scrollable" dense>
                    <v-list-item-group
                        v-model="selectedItem"
                        multiple
                        color="deep-orange darken-1"
                    >
                        <v-list-item
                            v-for="(item, index) in $store.getters
                                .notifications"
                            :key="index"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.title }}</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                    item.time | moment("from", "now")
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-system-bar
                    color="grey lighten-3"
                    class="text-center grey--text"
                >
                    <v-spacer></v-spacer>
                    <v-icon>mdi-trash-can-outline</v-icon>
                    <span
                        @click="clearNotify"
                        class="text-decoration-underline"
                    >
                        ล้างข้อมูลการแจ้งเตือนทั้งหมด</span
                    >
                    <v-spacer></v-spacer>
                </v-system-bar>
            </template>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "Notifications",
    computed: {
        iconColor() {
            return this.$store.getters.notifications.length > 0
                ? "green darken-3"
                : "grey lighten-1"
        },
    },
    methods: {
        reset() {
            this.color = "transparent"
        },
        onMenuToggle(opened) {
            if (opened) return
            this.selectedItem = []
        },
        async clearNotify() {
            this.$swal({
                title: "Clear all notifications ?",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.$store.commit("clearNotifications")
                    await this.$swal({
                        title: "Clear all notifications complete!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                    })
                }
            })
        },
        checkNotifications() {
            let getNewNotify = false
            this.$store.getters.notifications.map((each, index) => {
                if (each.status == "new") {
                    getNewNotify = true
                    this.selectedItem.forEach((item, index) => {
                        this.selectedItem[index] = item + 1
                    })
                    this.selectedItem.push(index)
                    each.status = "read"
                }
            })
            if (getNewNotify) {
                this.disabled = true
                this.color = "error"
                setTimeout(() => {
                    this.disabled = false
                }, 1500)
            }
        },
    },
    mounted() {
        this.checkNotifications()
    },
    beforeUpdate() {
        this.checkNotifications()
    },
    data: () => ({
        disabled: false,
        color: "transparent",
        selectedItem: [],
    }),
}
</script>

<style>
.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
