<template>
    <div class="mr-4">
        <v-card class="pa-0 ma-0" color="transparent" elevation="0">
            <v-card-text class="pa-0 ma-0">
                <v-container class="pa-0 ma-0">
                    <v-row align="center">
                        <v-col cols="12">
                            <v-chip
                                @click="openDialog"
                                color="primary"
                                small
                                pill
                                class="font-weight-bold"
                            >
                                {{ parseInt(weather_current.temperature) }}
                                &deg;C
                                <v-avatar right>
                                    <v-img
                                        class="justify-center"
                                        :src="
                                            'https://openweathermap.org/img/wn/' +
                                            weather_current.icon +
                                            '.png'
                                        "
                                    ></v-img>
                                </v-avatar>
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog_weather" width="450">
            <v-card class="pa-0 ma-0 bg-weather" rounded="lg">
                <v-card-text class="pa-0">
                    <v-container fluid>
                        <v-row align="center" class="pa-4">
                            <v-col cols="6" md="8">
                                <p class="text-h6 text-md-h5 mb-0 white--text">
                                    {{ company_name }}
                                </p>
                                <p
                                    class="text-subtitle-1 mt-0 mb-n4 mb-md-n4 white--text"
                                >
                                    {{ new Date() | moment("ddd, D") }}
                                    <v-icon color="white" small
                                        >mdi-clock-outline</v-icon
                                    >
                                    {{ new Date() | moment("HH:00") }}
                                </p>
                                <v-img
                                    width="50%"
                                    class="mb-n4 ml-n3 mb-md-n8 ml-md-n8"
                                    :src="
                                        'https://openweathermap.org/img/wn/' +
                                        weather_current.icon +
                                        '@4x.png'
                                    "
                                ></v-img>
                                <p
                                    class="text-subtitle-2 text-md-subtitle-1 font-weight-bold white--text"
                                >
                                    {{ weather_current.name }}
                                </p>
                            </v-col>
                            <v-col cols="6" md="4" align="right">
                                <p class="text-h3 text-md-h2 white--text">
                                    {{ parseInt(weather_current.temperature) }}
                                    &deg;
                                </p>
                                <p class="text-subtitle-2 white--text">
                                    <v-icon color="white" large
                                        >mdi-water</v-icon
                                    >
                                    {{ weather_current.humidity }} %
                                </p>
                            </v-col>
                        </v-row>
                        <v-sheet>
                            <v-slide-group v-model="index_slide" show-arrows>
                                <v-slide-item
                                    v-for="(item, index) in weather_forecast"
                                    :key="index"
                                >
                                    <v-card
                                        class="ma-1 transparent"
                                        width="120"
                                        rounded="lg"
                                    >
                                        <v-list three-line align="center">
                                            <p class="mb-0 font-weight-bold">
                                                {{
                                                    item.dt_txt
                                                        | moment("ddd, D")
                                                }}
                                            </p>
                                            <v-icon small
                                                >mdi-clock-outline</v-icon
                                            >
                                            {{ item.dt_txt | moment("HH:mm") }}
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-icon
                                                        class="mb-n4 mt-n3"
                                                    >
                                                        <v-img
                                                            width="40%"
                                                            :src="
                                                                'https://openweathermap.org/img/wn/' +
                                                                item.weather[0]
                                                                    .icon +
                                                                '@4x.png'
                                                            "
                                                        ></v-img>
                                                    </v-list-item-icon>
                                                    <v-list-item-title
                                                        class="text-subtitle-2 font-weight-bold mb-6"
                                                    >
                                                        {{
                                                            item.weather[0].main
                                                        }}
                                                    </v-list-item-title>
                                                    <v-list-item-title
                                                        class="text-h5"
                                                    >
                                                        {{
                                                            parseInt(
                                                                item.main.temp
                                                            )
                                                        }}&deg;
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{
                                                            parseInt(
                                                                item.main
                                                                    .temp_min
                                                            )
                                                        }}&deg; /
                                                        {{
                                                            parseInt(
                                                                item.main
                                                                    .temp_max
                                                            )
                                                        }}&deg;
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle>
                                                        <v-icon small
                                                            >mdi-water</v-icon
                                                        >
                                                        {{ item.main.humidity }}
                                                        %
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-container>
                </v-card-text>
                <!-- 
                { 
                    "dt": 1681203600, 
                    "main": { 
                        "temp": 41.8, 
                        "feels_like": 43.3, 
                        "temp_min": 41.52, 
                        "temp_max": 41.8, 
                        "pressure": 1006, 
                        "sea_level": 1006, 
                        "grnd_level": 974, 
                        "humidity": 23, 
                        "temp_kf": 0.28 
                    }, 
                    "weather": [ 
                        { 
                            "id": 802, 
                            "main": "Clouds", 
                            "description": "scattered clouds", 
                            "icon": "03d" 
                        } 
                    ], 
                    "clouds": { 
                        "all": 30 
                    }, 
                    "wind": { 
                        "speed": 2.15, 
                        "deg": 155, 
                        "gust": 4.01 
                    }, 
                    "visibility": 10000,
                    "pop": 0, 
                    "sys": { 
                        "pod": "d" 
                    }, 
                    "dt_txt": "2023-04-11 09:00:00" 
                } -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from "moment"
import axios from "axios"
const openweather_api_key = process.env.VUE_APP_OPENWEATHER_API_KEY
export default {
    icon: "",
    name: "Weather",
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            const { data } = await axios.post(
                `https://api.openweathermap.org/data/2.5/weather?lat=${localStorage.lat}&lon=${localStorage.lng}&cnt=10&appid=${openweather_api_key}&units=metric`
            )
            this.weather_current = {
                temperature: data.main.temp,
                name: data.weather[0].main,
                icon: data.weather[0].icon,
                humidity: data.main.humidity,
            }
        },

        async openDialog() {
            console.log(
                `https://api.openweathermap.org/data/2.5/forecast?lat=${localStorage.lat}&lon=${localStorage.lng}&cnt=40&appid=${openweather_api_key}&units=metric`
            )
            const { data } = await axios.post(
                `https://api.openweathermap.org/data/2.5/forecast?lat=${localStorage.lat}&lon=${localStorage.lng}&cnt=40&appid=${openweather_api_key}&units=metric`
            )

            const filter_list = data.list.filter((each) => {
                let x = moment(each.dt_txt).add(7, "hours").format()
                each.dt_txt = x
                return x >= moment().format()
            })

            this.weather_forecast = filter_list
            this.dialog_weather = true
        },
        convertDate(date) {
            return moment(date).utc()
        },
    },
    data: () => ({
        company_name: localStorage.company_name,
        index_slide: 0,
        currentLocation: {},
        dialog_weather: false,
        weather_data: {},
        weather_current: {
            temperature: "",
            code: "",
            name: "",
            icon: "",
            humidity: "",
        },
        weather_forecast: "",
    }),
    computed: {},
}
</script>

<style scoped>
.bg-weather {
    background: #ffffff url("@/assets/images/sunny.avif") no-repeat center top;

    background-attachment: scroll;
}
.theme--light.v-sheet {
    background-color: #ffffff00;
}
.theme--light.v-list {
    background-color: #ffffffaf;
}
</style>
