import Vue from "vue"
import App from "./App.vue"
import VueCompositionAPI from "@vue/composition-api"

// plugins
import router from "./router"
import store from "./store"
import i18n from "./plugins/i18n"
import vuetify from "./plugins/vuetify"
import VueThermometer from "vuejs-thermometer"
import Gauge from "@chrisheanan/vue-gauge"
import axios from "axios"
import { Service } from "axios-middleware"
import VueSocketIO from "vue-socket.io"
import SocketIO from "socket.io-client"
import VueSweetalert2 from "vue-sweetalert2"
import VueLottiePlayer from "vue-lottie-player"
import moment from "moment"
import DatePicker from "vue2-datepicker"
import VueAWN from "vue-awesome-notifications"
// css
import "sweetalert2/dist/sweetalert2.min.css"
import "./assets/reset.css"
import "vue2-datepicker/index.css"

const service = new Service(axios)

service.register({
    onResponseError(error) {
        if (error.response.status == "403" || error.response.status == "401") {
            window.location.href = `/${error.response.status}`
        }
        return error
    },
})

Vue.use(VueThermometer)
Vue.use(Gauge)
Vue.use(VueCompositionAPI)
Vue.use(require("vue-moment"), {
    moment,
})
Vue.use(VueSweetalert2)
Vue.use(VueLottiePlayer)
Vue.use(DatePicker)

const options = {
    transports: ["websocket", "polling"],
    withCredentials: true,
    query: {
        token_smf: localStorage.token_smf,
    },
}

Vue.use(
    new VueSocketIO({
        debug: true,
        connection: SocketIO(process.env.VUE_APP_SOCKET_ENDPOINT, options), //options object is Optional
        vuex: {
            store,
            actionPrefix: "SOCKET_",
            mutationPrefix: "SOCKET_",
        },
    })
)

const optionsVueAWN = {}

Vue.use(VueAWN, optionsVueAWN)

Vue.mixin({
    methods: {
        globalCheckToken: async function () {
            const verify = await axios.get(
                `${process.env.VUE_APP_API_HOST}/auth/verify`,
                this.bearerToken
            )
            if (verify.status) {
                const menus = [...verify.data.menus]
                return menus
            } else {
                localStorage.token_smf = ""
                localStorage.user_lang = "en"
                localStorage.user_role = ""
                this.$store.commit("setLogInStatus", false)
                window.location.href = `/${error.response.status}`
            }
        },
    },
    data: function () {
        return {
            bearerToken: {
                headers: {
                    Authorization: "Bearer " + localStorage.token_smf,
                },
            },
            userRole: localStorage.user_role,
            companyName: localStorage.company_name,
        }
    },
})

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app")
