<template>
    <div>
        <v-container fluid v-if="!loaddata">
            <v-row>
                <v-col cols="6">
                    <h3>
                        <v-icon class="mr-4">mdi-cog</v-icon>
                        {{ $t("settings.title") }} > Farm
                    </h3>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn
                        small
                        dark
                        rounded
                        elevation="2"
                        color="green darken-3"
                        @click="newData"
                    >
                        <v-icon>mdi-plus</v-icon> เพิ่มข้อมูลใหม่
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        id="mytable"
                        :headers="headers"
                        :items="farms"
                        :items-per-page="10"
                        class="elevation-1"
                    >
                        <template v-slot:[`item.no`]="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.deveui_sensor`]="{ item }">
                            {{ getNodeData(item.devices, "Sensor") }}
                        </template>
                        <template v-slot:[`item.deveui_control`]="{ item }">
                            {{ getNodeData(item.devices, "Control") }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editData(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                v-if="item.farm_status != 'Farming'"
                                small
                                class="mr-2"
                                @click="deleteData(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12">
                    <v-skeleton-loader type="article"></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            right
            shaped
            bottom
            outlined
            height="5%"
            color="success"
        >
            {{ text }}
        </v-snackbar>
        <v-dialog v-model="confirm_form" width="300" height="100">
            <v-card>
                <v-card-title class="white--text green darken-3">
                    คุณต้องการลบข้อมูลหรือไม่?
                </v-card-title>
                <v-card-actions class="justify-center">
                    <v-btn
                        rounded
                        color="grey lighten-5"
                        @click="reset_confirm"
                    >
                        ยกเลิก
                    </v-btn>
                    <v-btn
                        rounded
                        color="white--text green darken-3"
                        @click="
                            form_type == 'delete'
                                ? deleteSelectData()
                                : saveNewData()
                        "
                    >
                        ยืนยัน
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_form" width="600" @click:outside="reset">
            <v-card>
                <v-card-title class="white--text green darken-3">
                    {{
                        form_type == "edit" ? "แก้ไขข้อมูล" : "เพิ่มข้อมูลใหม่"
                    }}
                    <!-- เพิ่มข้อมูลใหม่ -->
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row class="mt-3">
                            <v-col cols="12" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.farm_name"
                                    outlined
                                    dense
                                    light
                                    :rules="farmName"
                                    rounded
                                    label="ชื่อฟาร์ม"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" align="right" class="mini-text">
                                <v-combobox
                                    v-model="form.devices[0]"
                                    :items="filterNodeData(devices, 'Sensor')"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="Node Sensor"
                                    item-text="device_name"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12" md="6" align="right" class="mini-text">
                                <v-combobox
                                    v-model="form.devices[1]"
                                    :items="filterNodeData(devices, 'Control')"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="Node Control"
                                    item-text="device_name"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn small rounded color="grey lighten-5" @click="reset">
                        ยกเลิก
                    </v-btn>
                    <!-- <v-spacer></v-spacer> -->
                    <!-- :disabled="formConditionRule" -->
                    <v-btn
                        small
                        rounded
                        :disabled="formConditionRule"
                        color="white--text green darken-3"
                        @click="
                            form_type == 'edit' ? saveEditData() : saveNewData()
                        "
                    >
                        บันทึก
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SettingFarm",
  components: {},
  mounted() {
    this.getData();
  },
  data: () => ({
    loaddata: false,
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Farm Name", value: "farm_name" },
      { text: "DEVEUI of Node Sensor", value: "deveui_sensor" },
      { text: "DEVEUI of Node Control", value: "deveui_control" },
      { text: "Farm Status", value: "farm_status" },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
      },
    ],
    farms: [],
    dialog_form: false,
    snackbar: false,
    text: "Successfully Update!",
    timeout: 3000,
    farmName: [(v) => !!v || "The input is required"],
    form_type: "",
    errors: [],
    form: {
      farm_id: "",
      farm_name: "",
      farm_status: "",
      company_id: "",
      devices: [],
    },
  }),
  computed: {
    formConditionRule() {
      return !(
        this.form.devices[0] &&
        this.form.devices[1] &&
        this.form.farm_name != ""
      );
    },
  },
  methods: {
    filterNodeData(devices, type) {
      const filter = devices.filter((device) => {
        if (device.device_type == type) {
          return device;
        }
      });
      return filter;
    },
    getNodeData(devices, type) {
      const filter = this.filterNodeData(devices, type);
      const result = filter.length > 0 ? filter[0].device_name : "";
      return result;
    },
    async getData() {
      this.loaddata = true;
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_HOST}/setting/farms`,
        this.bearerToken
      );
      this.farms = [...data.farm];
      this.devices = [...data.device];
      this.loaddata = false;
      // console.log("this_devices", this.devices)
      // console.log("this.farms", this.farms)
    },
    newData() {
      this.dialog_form = true;
      this.form_type = "new";
    },
    async saveNewData() {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_HOST}/setting/farms`,
        this.form,
        this.bearerToken
      );
      this.reset();
      this.getData();
      this.saveNewDataModal();
    },
    editData(data) {
      this.dialog_form = true;
      this.form_type = "edit";
      this.form = { ...data };
    },
    async saveEditData() {
      const { data } = await axios.put(
        `${process.env.VUE_APP_API_HOST}/setting/farms/${this.form.farm_id}`,
        this.form,
        this.bearerToken
      );
      this.reset();
      this.getData();
      this.editDataModal();
    },

    deleteData(data) {
      this.confirm_form = true;
      this.form_type = "delete";
      this.form = { ...data };
    },
    async deleteSelectData() {
      const { data } = await axios.delete(
        `${process.env.VUE_APP_API_HOST}/setting/farms/${this.form.farm_id}`,
        this.bearerToken
      );
      this.confirm_form = false;
      this.getData();
      this.deleteDataModal();
    },
    reset() {
      this.dialog_form = false;
      this.form = {
        farm_id: "",
        farm_name: "",
        farm_status: "",
        company_id: "",
        devices: [],
      };
    },
    reset_confirm() {
      this.confirm_form = false;
      this.form = {
        farm_id: "",
        farm_name: "",
        farm_status: "",
        company_id: "",
        devices: [],
      };
    },
    async editDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async deleteDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async saveNewDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<style>
#mytable .v-data-table__wrapper table thead {
  background: rgb(220, 220, 220) !important;
}
</style>
