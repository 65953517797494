<template>
    <div>
        <v-container fluid v-if="!loaddata">
            <v-row>
                <v-col cols="6">
                    <h3>
                        <v-icon class="mr-4">mdi-cog</v-icon>
                        {{ $t("settings.title") }} > Node Device
                    </h3>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn
                        small
                        dark
                        rounded
                        elevation="2"
                        color="green darken-3"
                        @click="newData"
                    >
                        <v-icon>mdi-plus</v-icon> เพิ่มข้อมูลใหม่
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        id="mytable"
                        :headers="headers"
                        :items="devices"
                        :items-per-page="10"
                        class="elevation-1"
                    >
                        <template v-slot:[`item.no`]="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editData(item)">
                                mdi-pencil
                            </v-icon>

                            <v-icon
                                small
                                class="mr-2"
                                @click="viewHistory(item)"
                            >
                                mdi-file-find-outline
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="deleteData(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12">
                    <v-skeleton-loader type="article"></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            right
            shaped
            bottom
            outlined
            height="5%"
            color="success"
        >
            {{ text }}
        </v-snackbar>

        <v-dialog v-model="confirm_form" width="300" height="100">
            <v-card>
                <v-card-title class="white--text green darken-3">
                    คุณต้องการลบข้อมูลหรือไม่?
                </v-card-title>
                <v-card-actions class="justify-center">
                    <v-btn
                        rounded
                        color="grey lighten-5"
                        @click="reset_confirm"
                    >
                        ยกเลิก
                    </v-btn>
                    <v-btn
                        rounded
                        color="white--text green darken-3"
                        @click="
                            form_type == 'delete'
                                ? deleteSelectData()
                                : saveNewData()
                        "
                    >
                        ยืนยัน
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_form" width="600" @click:outside="reset">
            <v-card>
                <v-card-title class="white--text green darken-3">
                    {{
                        form_type == "edit" ? "แก้ไขข้อมูล" : "เพิ่มข้อมูลใหม่"
                    }}
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="mini-text">
                                <v-radio-group v-model="form.device_type" row>
                                    <v-radio
                                        label="Sensor"
                                        value="Sensor"
                                        color="green darken-3"
                                    ></v-radio>
                                    <v-radio
                                        label="Control"
                                        value="Control"
                                        color="green darken-3"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.device_name"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="Device Name"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.dev_addr"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="Device Address"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.dev_eui"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="Device EUI"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.app_key"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="App Key"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn small rounded color="grey lighten-5" @click="reset">
                        ยกเลิก
                    </v-btn>
                    <v-btn
                        small
                        rounded
                        :disabled="formConditionRule"
                        color="white--text green darken-3"
                        @click="
                            form_type == 'edit' ? saveEditData() : saveNewData()
                        "
                    >
                        บันทึก
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_uplink"
            width="1200"
            @click:outside="closeHistory"
        >
            <v-card dense>
                <v-card-title class="white--text green darken-3">
                    Uplink History Log
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="7">
                                <v-chip
                                    small
                                    color="blue darken-4 white--text font-weight-bold"
                                    >Event: 1 = Uplink | 0 = Join
                                </v-chip>
                                <br />
                                <LineChart
                                    class="d-none d-md-flex"
                                    :chartData="summaryChartData"
                                    :options="summaryChartOptions"
                                    :height="150"
                                />
                                <br />
                                <v-row>
                                    <v-col
                                        v-for="(rejoin, ind) in uplink_log_join"
                                        :key="ind"
                                        cols="12"
                                        md="6"
                                        lg="4"
                                    >
                                        <v-card dense>
                                            <v-list-item three-line>
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        class="text-h6 mb-1"
                                                    >
                                                        Rejoin #{{ ind + 1 }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle
                                                        >UP:
                                                        {{
                                                            rejoin[0].time_show
                                                        }}</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                        >JOIN:
                                                        {{
                                                            rejoin[1].time_show
                                                        }}</v-list-item-subtitle
                                                    >
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-data-table
                                    dense
                                    :headers="headers_log"
                                    :items="uplink_log"
                                    :items-per-page="15"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.event`]="{ item }">
                                        <v-chip
                                            v-if="item.event == 'Join'"
                                            small
                                            color="red darken-3 white--text font-weight-bold"
                                        >
                                            Join
                                        </v-chip>
                                        <v-chip
                                            v-else
                                            small
                                            color="green white--text font-weight-bold"
                                        >
                                            Uplink
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
import { Chart, registerables } from "chart.js"
import "chartjs-adapter-moment"
import { LineChart } from "vue-chart-3"

Chart.register(...registerables)

export default {
    name: "SettingDevice",
    components: { LineChart },
    mounted() {
        this.getData()
    },
    data: () => ({
        loaddata: false,
        headers: [
            {
                text: "#",
                align: "start",
                sortable: false,
                value: "no",
            },
            { text: "Device Name", value: "device_name" },
            { text: "Device Type", value: "device_type" },
            { text: "Device Address", value: "dev_addr" },
            { text: "Device EUI", value: "dev_eui" },
            { text: "App Key", value: "app_key" },
            { text: "Device Status", value: "device_status" },
            {
                text: "Actions",
                sortable: false,
                value: "actions",
            },
        ],
        headers_log: [
            { text: "Date", value: "time_show" },
            { text: "Event", value: "event" },
        ],
        devices: [],
        dialog_form: false,
        snackbar: false,
        text: "Successfully Update!",
        timeout: 3000,
        form_type: "",
        form: {
            device_id: "",
            device_name: "",
            device_type: "",
            dev_addr: "",
            dev_eui: "",
            app_key: "",
            device_status: "",
        },
        dialog_uplink: false,
        uplink_log: [],
        uplink_log_join: [],
        summaryChartData: {},
        summaryChartOptions: {},
        confirm_form: false,
    }),
    computed: {
        formConditionRule() {
            return !(
                this.form.device_name &&
                this.form.device_type &&
                this.form.dev_addr &&
                this.form.dev_eui &&
                this.form.app_key != ""
            )
        },
    },
    methods: {
        async getData() {
            this.loaddata = true
            const { data } = await axios.get(
                `${process.env.VUE_APP_API_HOST}/setting/devices`,
                this.bearerToken
            )
            this.devices = [...data]
            this.loaddata = false
        },
        newData() {
            this.dialog_form = true
            this.form_type = "new"
        },
        async saveNewData() {
            const { data } = await axios.post(
                `${process.env.VUE_APP_API_HOST}/setting/devices`,
                this.form,
                this.bearerToken
            )
            this.reset()
            this.saveNewDataModal()
            this.getData()
        },
        editData(data) {
            this.dialog_form = true
            this.form_type = "edit"
            this.form = { ...data }
        },
        async viewHistory(node) {
            this.dialog_uplink = true
            const { data } = await axios.get(
                `${process.env.VUE_APP_API_HOST}/setting/devices/${node.device_id}/uplink-log`,
                this.bearerToken
            )
            let test = data.log.split("\n")
            let array_redesign = []
            let array_graph = []
            let array_join = []

            test.sort(function (a, b) {
                let split_a = a.split(" ")
                let time_idx_a = split_a.length == 16 ? 6 : 5
                let time_a = moment(
                    split_a[time_idx_a].replace('time="', "").replace('"', "")
                ).format("YYYY-MM-DD HH:mm:ss")

                let split_b = b.split(" ")
                let time_idx_b = split_b.length == 16 ? 6 : 5
                let time_b = moment(
                    split_b[time_idx_b].replace('time="', "").replace('"', "")
                ).format("YYYY-MM-DD HH:mm:ss")

                let keyA = new Date(time_a)
                let keyB = new Date(time_b)

                // Compare the 2 dates
                if (keyA < keyB) return -1
                if (keyA > keyB) return 1
                return 0
            })

            // console.log(test)

            test.map((each) => {
                let splittext = each.split(" ")
                let last_idx = splittext.length - 1
                let time_idx = last_idx == 15 ? 6 : 5
                let time_show = moment(
                    splittext[time_idx].replace('time="', "").replace('"', "")
                ).format("DD-MMM-YY HH:mm:ss")
                let time_graph = moment(
                    splittext[time_idx].replace('time="', "").replace('"', "")
                ).format("YYYY-MM-DD HH:mm:ss")

                let event = "Uplink"
                let event_num = 1
                if (
                    splittext[last_idx].indexOf("join") > 0 &&
                    array_redesign[array_redesign.length - 1]
                ) {
                    event = "Join"
                    event_num = 0

                    let temp = []
                    temp.push(array_redesign[array_redesign.length - 1])
                    temp.push({
                        time_show: time_show,
                        event: event,
                    })
                    array_join.push(temp)
                }

                array_redesign.push({
                    time_show: time_show,
                    event: event,
                })

                array_graph.push({
                    x: time_graph,
                    y: event_num,
                })
            })

            this.uplink_log = array_redesign
            this.uplink_log_join = array_join

            this.summaryChartData = {
                datasets: [
                    {
                        label: "Event Log",
                        data: array_graph,
                        backgroundColor: "#1A237E",
                        // borderColor: "#7FD1B9",
                    },
                ],
            }
            this.summaryChartOptions = {
                scales: {
                    x: {
                        type: "time",
                        distribution: "series",
                        time: {
                            unit: "day",
                            displayFormats: {
                                hour: "DD-MMM-YY HH:00",
                            },
                            tooltipFormat: "DD-MMM-YY HH:mm:ss",
                        },
                        min: array_graph[0].x,
                        max: array_graph[array_graph.length - 1].x,
                    },
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 1,
                    },
                },
                elements: {
                    point: {
                        radius: 1,
                    },
                },
                tooltips: {
                    enabled: true,
                },
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true,
                        },
                    },
                },
            }
        },
        closeHistory() {
            this.uplink_log = []
            this.uplink_log_join = []
            this.summaryChartData = {
                datasets: [
                    {
                        label: "Event Log",
                        data: [],
                        backgroundColor: "#1A237E",
                    },
                ],
            }
            this.summaryChartOptions = {}
            this.dialog_uplink = false
        },
        async saveEditData() {
            const { data } = await axios.put(
                `${process.env.VUE_APP_API_HOST}/setting/devices/${this.form.device_id}`,
                this.form,
                this.bearerToken
            )
            this.reset()
            this.getData()
            this.editDataModal()
        },
        reset() {
            this.dialog_form = false
            this.form = {
                device_id: "",
                device_name: "",
                device_type: "",
                dev_addr: "",
                dev_eui: "",
                app_key: "",
                device_status: "",
            }
        },
        deleteData(data) {
            this.confirm_form = true
            this.form_type = "delete"
            this.form = { ...data }
        },
        async deleteSelectData() {
            const { data } = await axios.delete(
                `${process.env.VUE_APP_API_HOST}/setting/devices/${this.form.device_id}`,
                this.bearerToken
            )
            this.confirm_form = false
            this.deleteDataModal()
            this.getData()
        },
        reset_confirm() {
            this.confirm_form = false
            this.form = {
                device_id: "",
                device_name: "",
                device_type: "",
                dev_addr: "",
                dev_eui: "",
                app_key: "",
                device_status: "",
            }
        },
        async editDataModal() {
            this.$swal({
                title: "Update Successfully!",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
            })
        },
        async deleteDataModal() {
            this.$swal({
                title: "Update Successfully!",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
            })
        },
        async saveNewDataModal() {
            this.$swal({
                title: "Update Successfully!",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
            })
        },
    },
}
</script>

<style>
#mytable .v-data-table__wrapper table thead {
    background: rgb(220, 220, 220) !important;
}
</style>
