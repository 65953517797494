<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <h3>
                        <v-icon class="mr-4">mdi-view-dashboard</v-icon>
                        {{ $t("dashboard.title") }}
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-row v-if="!loadFarm">
                        <v-col cols="12" md="4" class="pa-0 pr-1 mt-2">
                            <v-list class="bg-test-1" rounded="lg">
                                <v-list-item class="px-1 mx-0">
                                    <v-list-item-icon class="px-0 mx-0">
                                        <v-icon
                                            x-large
                                            v-text="'mdi-sprout'"
                                        ></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content align="right">
                                        <v-list-item-title>
                                            <h1>
                                                {{ showFarmStatus("Farming") }}
                                            </h1>
                                        </v-list-item-title>
                                        <p class="mb-0 pb-0">
                                            โรงเรือนที่มีการเพาะปลูกอยู่
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" md="4" class="pa-0 pr-1 mt-2">
                            <v-list class="bg-test-3" rounded="lg">
                                <v-list-item class="px-1 mx-0">
                                    <v-list-item-icon class="px-0 mx-0">
                                        <v-icon
                                            x-large
                                            v-text="'mdi-alert-circle'"
                                        ></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content align="right">
                                        <v-list-item-title>
                                            <h1>
                                                {{ showFarmStatus("Error") }}
                                            </h1>
                                        </v-list-item-title>
                                        <p class="mb-0 pb-0">
                                            โรงเรือนที่ไม่สามารถติดต่อกับเซ็นเซอร์ได้
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" md="4" class="pa-0 pr-1 mt-2">
                            <v-list class="bg-test-2" rounded="lg">
                                <v-list-item class="px-1 mx-0">
                                    <v-list-item-icon class="px-0 mx-0">
                                        <v-icon
                                            x-large
                                            v-text="'mdi-home-variant-outline'"
                                        ></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content align="right">
                                        <v-list-item-title>
                                            <h1>
                                                {{ showFarmStatus("Empty") }}
                                            </h1>
                                        </v-list-item-title>
                                        <p class="mb-0 pb-0">โรงเรือนเปล่า</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" md="4" v-for="idx in 3" :key="idx">
                            <v-skeleton-loader type="image"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" class="pa-0 pt-2 pr-1">
                    <v-card v-if="!loadGraph">
                        <v-card-text class="pa-2">
                            <v-container fluid class="pa-0 pt-2">
                                <v-row>
                                    <v-col cols="12">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr class="bg-grey-main">
                                                        <th class="text-center">
                                                            พืชพรรณ
                                                        </th>
                                                        <th class="text-center">
                                                            จำนวนครั้งที่ปลูก
                                                        </th>
                                                        <th class="text-center">
                                                            เกรดที่ดีที่สุด
                                                        </th>
                                                        <th class="text-center">
                                                            <v-btn
                                                                icon
                                                                color="green darken-3"
                                                                @click="
                                                                    gotoWindow(
                                                                        0
                                                                    )
                                                                "
                                                            >
                                                                <v-icon
                                                                    >mdi-chart-bar</v-icon
                                                                >
                                                            </v-btn>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(
                                                            each, idx
                                                        ) in plants"
                                                        :key="each.plant_id"
                                                    >
                                                        <td>
                                                            {{
                                                                each.plant_name
                                                            }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{
                                                                each.num_collect
                                                            }}
                                                        </td>
                                                        <td
                                                            class="text-center font-weight-bold"
                                                        >
                                                            {{
                                                                each.best_grade
                                                            }}
                                                        </td>
                                                        <td class="text-center">
                                                            <v-btn
                                                                icon
                                                                color="blue darken-3"
                                                                @click="
                                                                    gotoWindow(
                                                                        idx + 1
                                                                    )
                                                                "
                                                            >
                                                                <v-icon
                                                                    >mdi-chart-bar</v-icon
                                                                >
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                    <v-skeleton-loader
                        v-else
                        cols="12"
                        md="4"
                        type="article"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="8" class="pa-0 pt-2">
                    <v-card v-if="!loadGraph">
                        <v-card-text class="pa-2">
                            <v-container fluid class="pa-0 pt-2">
                                <v-row align="center">
                                    <v-col cols="12">
                                        <v-window
                                            touchless
                                            v-model="showInfo"
                                            vertical
                                        >
                                            <v-window-item :key="0">
                                                <h3 class="pt-0">
                                                    รายงานแสดงผลสัดส่วนผลผลิตสรุปตามเกรดต่างๆที่ผลิตได้
                                                </h3>
                                                <PieChart
                                                    :chartData="pieGraphData"
                                                    :options="pieGraphOptions"
                                                />
                                            </v-window-item>
                                            <v-window-item
                                                v-for="(
                                                    each, idx
                                                ) in plantsData"
                                                :key="idx + 1"
                                            >
                                                <v-row align="center">
                                                    <v-col cols="12">
                                                        <v-card flat>
                                                            <v-card-text>
                                                                <BarChart
                                                                    :chartData="
                                                                        barGraphData(
                                                                            each
                                                                        )
                                                                    "
                                                                    :options="
                                                                        barGraphOptions
                                                                    "
                                                                />
                                                                <v-simple-table
                                                                    dense
                                                                    class="mt-6"
                                                                >
                                                                    <template
                                                                        v-slot:default
                                                                    >
                                                                        <thead>
                                                                            <tr
                                                                                class="bg-grey-main"
                                                                            >
                                                                                <th
                                                                                    class="text-center"
                                                                                >
                                                                                    วันที่เก็บเกี่ยว
                                                                                </th>
                                                                                <th
                                                                                    class="text-center"
                                                                                >
                                                                                    ระยะเวลาในการเพาะปลูก
                                                                                    (วัน)
                                                                                </th>
                                                                                <th
                                                                                    class="text-center"
                                                                                >
                                                                                    ส่งขายกรุงเทพฯ
                                                                                    (กก.)
                                                                                </th>
                                                                                <th
                                                                                    class="text-center"
                                                                                >
                                                                                    ส่งขายในชุมชน
                                                                                    (กก.)
                                                                                </th>
                                                                                <th
                                                                                    class="text-center"
                                                                                >
                                                                                    แยกไว้ทำปุ๋ย
                                                                                    (กก.)
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr
                                                                                v-for="(
                                                                                    each_date,
                                                                                    dataidx
                                                                                ) in each.date"
                                                                                :key="
                                                                                    dataidx
                                                                                "
                                                                            >
                                                                                <td
                                                                                    class="text-center"
                                                                                >
                                                                                    {{
                                                                                        each_date
                                                                                    }}
                                                                                </td>
                                                                                <td
                                                                                    class="text-center"
                                                                                >
                                                                                    {{
                                                                                        each
                                                                                            .growth_days[
                                                                                            dataidx
                                                                                        ]
                                                                                    }}
                                                                                </td>
                                                                                <td
                                                                                    class="text-center"
                                                                                >
                                                                                    {{
                                                                                        each
                                                                                            .grade_a[
                                                                                            dataidx
                                                                                        ]
                                                                                    }}
                                                                                </td>
                                                                                <td
                                                                                    class="text-center"
                                                                                >
                                                                                    {{
                                                                                        each
                                                                                            .grade_b[
                                                                                            dataidx
                                                                                        ]
                                                                                    }}
                                                                                </td>
                                                                                <td
                                                                                    class="text-center"
                                                                                >
                                                                                    {{
                                                                                        each
                                                                                            .grade_c[
                                                                                            dataidx
                                                                                        ]
                                                                                    }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </v-simple-table>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-window-item>
                                        </v-window>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                    <v-skeleton-loader
                        v-else
                        cols="12"
                        md="8"
                        type="card"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios"
import { Chart, registerables } from "chart.js"
import { BarChart, PieChart } from "vue-chart-3"
import Weather from "../components/Weather.vue"

Chart.register(...registerables)

export default {
    name: "Dashboard",
    components: { BarChart, Weather, PieChart },
    data: () => ({
        loadFarm: false,
        loadGraph: false,
        showInfo: 0,
        farm_status: [],
        plants: [],
        plantsData: [],
        pieGraphOptions: {},
        barGraphOptions: {
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: "วันที่เก็บเกี่ยว",
                        font: {
                            family: "Sarabun",
                            size: 16,
                            lineHeight: 1.2,
                        },
                        padding: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                },
                y: {
                    beginAtZero: true,
                    max: 100,
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: "%",
                        font: {
                            family: "Sarabun",
                            size: 16,
                            lineHeight: 1.2,
                        },
                        padding: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                },
            },
            legend: {
                display: false,
            },
            plugins: {
                datalabels: {
                    display: true,
                    formatter: (val, ctx) => {
                        // Grab the label for this value
                        const label = ctx.chart.data.labels[ctx.dataIndex]
                        // Format the number with 2 decimal places
                        const formattedVal = Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 0,
                        }).format(val)
                        // Put them together
                        return `${formattedVal}`
                    },
                    color: "#fff",
                    backgroundColor: "#404040",
                },
            },
        },
        dates: [],
        pieGraphData: {},
    }),
    computed: {
        dateRangeText() {
            return this.dates.join(" - ")
        },
    },
    methods: {
        async getSummaryFarm() {
            this.loadFarm = true
            let { data } = await axios.get(
                `${process.env.VUE_APP_API_HOST}/dashboard/farm-status`,
                this.bearerToken
            )
            this.farm_status = data
            this.loadFarm = false
        },
        async getGraphData() {
            this.loadGraph = true
            let { data } = await axios.post(
                `${process.env.VUE_APP_API_HOST}/dashboard/info`,
                {},
                this.bearerToken
            )
            this.plants = data.plants
            this.plantsData = data.data
            this.pieGraphData = {
                labels: [
                    "เกรด A : ส่งขายกรุงเทพฯ",
                    "เกรด B : ส่งขายในชุมชน",
                    "เกรด C : แยกไว้ทำปุ๋ย",
                ],
                datasets: [
                    {
                        data: data.summary,
                        backgroundColor: [
                            "#9fe3b2",
                            "#7ebef2",
                            "#f2917e",
                            "#f2d37e",
                        ],
                        hoverOffset: 4,
                    },
                ],
            }
            this.loadGraph = false
        },
        barGraphData(each) {
            let temp = {
                plant_id: each.plant_id,
                plant_name: each.plant_name,
                labels: each.date, // label
                datasets: [
                    {
                        label: "เกรด A : ส่งขายกรุงเทพฯ",
                        data: each.pc_grade_a,
                        backgroundColor: ["#9fe3b2"],
                    },
                    {
                        label: "เกรด B : ส่งขายในชุมชน",
                        data: each.pc_grade_b,
                        backgroundColor: ["#7ebef2"],
                    },
                    {
                        label: "เกรด C : แยกไว้ทำปุ๋ย",
                        data: each.pc_grade_c,
                        backgroundColor: ["#f2d37e"],
                    },
                    // {
                    //     label: "Grade D",
                    //     data: each.pc_grade_d,
                    //     backgroundColor: ["#f2917e"],
                    // },
                ],
            }
            return temp
        },
        showFarmStatus(status) {
            let response = 0
            this.farm_status.map((each) => {
                if (each.farm_status == status) {
                    response = each.num_status
                }
            })
            return response
        },
        gotoWindow(window) {
            this.showInfo = window
        },
    },
    mounted() {
        this.getSummaryFarm()
        this.getGraphData()
    },
    watch: {},
}
</script>
