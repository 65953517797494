<template>
    <v-card class="pa-1" elevation="0" rounded="lg">
        <v-card-title class="pa-2 justify-space-between">
            {{ farm.farm_name }}
            <v-btn elevation="0" fab x-small color="primary">
                <v-icon dark> mdi-magnify </v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 pt-1">
            <p :class="`font-weight-light grey--text text-center`">
                {{ farm.income }} THB
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "IncomeGlasshouse",
    props: ["farm"],
    mounted() {},
    data: () => ({}),
    methods: {},
    beforeCreate() {},
}
</script>

<style scoped></style>
