<template>
  <div>
    <v-container fluid v-if="!loaddata">
      <v-row>
        <v-col cols="6">
          <h3 :style="'font-size: 16px !important'">
            <v-icon class="mr-1">mdi-cog</v-icon>
            {{ $t("settings.title") }} > User
          </h3>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
            small
            dark
            rounded
            elevation="2"
            color="green darken-3"
            @click="newData">
            <v-icon>mdi-plus</v-icon> เพิ่มผู้ใช้งานใหม่
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            id="mytable"
            :headers="headers"
            :items="users"
            :items-per-page="10"
            class="elevation-1">
            <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.fullname`]="{ item }">
              {{ item.firstname }} {{ item.lastname }}
            </template>
            <template v-slot:[`item.password`]="{ item }">
              ************
              <v-icon small class="ml-2 mb-2" @click="editPassword(item)">
                mdi-key
              </v-icon>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at | moment("DD-MMM-YYYY HH:mm:ss") }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editData(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteData(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      right
      shaped
      bottom
      outlined
      height="5%"
      color="success">
      {{ text }}
    </v-snackbar>

    <v-dialog v-model="confirm_form" width="300" height="100">
      <v-card>
        <v-card-title class="white--text green darken-3">
          คุณต้องการลบข้อมูลหรือไม่?
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn rounded color="grey lighten-5" @click="reset_confirm">
            ยกเลิก
          </v-btn>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            rounded
            color="white--text green darken-3"
            @click="form_type == 'delete' ? deleteSelectData() : saveNewData()">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

        <v-dialog v-model="dialog_form" width="600" @click:outside="reset">
            <v-card>
                <v-card-title class="white--text green darken-3">
                    {{
                        form_type == "edit"
                            ? "แก้ไขข้อมูลผู้ใช้งาน"
                            : "เพิ่มผู้ใช้งานใหม่"
                    }}
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row class="mt-3">
                            <v-col cols="12" md="6" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.firstname"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="ชื่อ"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.lastname"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="นามสกุล"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" align="right" class="mini-text">
                                <v-text-field
                                    v-model="form.username"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    label="User ID"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" align="right" class="mini-text">
                                <v-text-field
                                    v-if="form_type == 'new'"
                                    v-model="form.password"
                                    outlined
                                    dense
                                    light
                                    rounded
                                    :type="showpassword ? 'text' : 'password'"
                                    :append-icon="
                                        showpassword ? 'mdi-eye' : 'mdi-eye-off'
                                    "
                                    @click:append="showpassword = !showpassword"
                                    label="Password"
                                ></v-text-field>
                                <v-switch
                                    class="mt-0"
                                    v-if="form_type == 'edit'"
                                    color="green darken-3"
                                    v-model="form.user_status"
                                    :label="`สถานะ: ${
                                        form.user_status
                                            ? 'ใช้งาน'
                                            : 'ไม่ใช้งาน'
                                    }`"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12">
                                <span class="text-subtitle-2">Role</span>
                                <v-radio-group v-model="form.role" row>
                                  <v-col cols="12" md="4">
                                    <v-radio
                                        label="Admin"
                                        value="admin"
                                        color="green darken-3"
                                    ></v-radio>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-radio
                                        label="Farmer"
                                        value="farmer"
                                        color="green darken-3"
                                    ></v-radio>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-radio
                                        label="Guest"
                                        value="guest"
                                        color="green darken-3"
                                    ></v-radio>
                                  </v-col>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn small rounded color="grey lighten-5" @click="reset">
                        ยกเลิก
                    </v-btn>
                    <v-btn
                        small
                        rounded
                        :disabled="formConditionRule"
                        color="white--text green darken-3"
                        @click="
                            form_type == 'edit' ? saveEditData() : saveNewData()
                        "
                    >
                        บันทึก
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    <v-dialog v-model="dialog_password" width="400" @click:outside="reset_pw">
      <v-card>
        <v-card-title class="white--text green darken-3">
          Change Password
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-3">
              <v-col cols="12" align="right" class="mini-text">
                <v-text-field
                  v-model="formPassword.new_password"
                  outlined
                  dense
                  light
                  rounded
                  :type="showpassword ? 'text' : 'password'"
                  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showpassword = !showpassword"
                  label="New Password"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small rounded color="grey lighten-5" @click="resetPassword">
            ยกเลิก
          </v-btn>

          <v-btn
            small
            rounded
            :disabled="passwordConditionRule"
            color="white--text green darken-3"
            @click="saveEditPassword">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SettinUser",
  components: {},
  mounted() {
    this.getData();
  },
  data: () => ({
    loaddata: false,
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Full Name", value: "fullname" },
      { text: "User ID", value: "username" },
      { text: "Password", value: "password" },
      { text: "Role", value: "role" },
      { text: "Last Updated", value: "updated_at" },
      { text: "User Status", value: "user_status" },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
      },
    ],
    users: [],
    dialog_form: false,
    snackbar: false,
    text: "Successfully Update!",
    timeout: 3000,
    form_type: "",
    form: {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      role: "",
      user_status: "",
    },
    showpassword: false,
    dialog_password: false,
    formPassword: {
      new_password: "",
      user_id: "",
    },
  }),
  computed: {
    formConditionRule() {
      return !(
        this.form.firstname &&
        this.form.lastname &&
        this.form.username &&
        this.form.password &&
        this.form.role != ""
      );
    },
    passwordConditionRule() {
      return !(this.formPassword.new_password != "");
    },
  },
  methods: {
    async getData() {
      this.loaddata = true;
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_HOST}/setting/users`,
        this.bearerToken
      );
      this.users = [...data];
      this.loaddata = false;
    },
    newData() {
      this.dialog_form = true;
      this.form_type = "new";
    },
    async saveNewData() {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_HOST}/setting/users`,
        this.form,
        this.bearerToken
      );
      this.reset();
      this.getData();
      this.saveNewDataModal();
    },
    editData(data) {
      this.dialog_form = true;
      this.form_type = "edit";
      this.form = { ...data };
      this.form.user_status = this.form.user_status == "Active" ? true : false;
    },
    async saveEditData() {
      this.form.user_status =
        this.form.user_status == true ? "Active" : "Inactive";
      const { data } = await axios.put(
        `${process.env.VUE_APP_API_HOST}/setting/users/${this.form.user_id}`,
        this.form,
        this.bearerToken
      );
      this.reset();
      this.getData();
      this.editDataModal();
    },
    reset() {
      this.dialog_form = false;
      this.form = {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        role: "",
        user_status: "",
      };
      this.showpassword = false;
    },
    editPassword(data) {
      this.dialog_password = true;
      this.formPassword.new_password = "";
      this.formPassword.user_id = data.user_id;
    },
    async saveEditPassword() {
      const { data } = await axios.put(
        `${process.env.VUE_APP_API_HOST}/setting/users/change-pwd/${this.formPassword.user_id}`,
        this.formPassword,
        this.bearerToken
      );
      this.resetPassword();
      this.editDataModal();
    },
    resetPassword() {
      this.dialog_password = false;
      this.formPassword.new_password = "";
      this.showpassword = false;
      this.getData();
    },
    deleteData(data) {
      this.confirm_form = true;
      this.form_type = "delete";
      this.form = { ...data };
    },
    async deleteSelectData() {
      const { data } = await axios.delete(
        `${process.env.VUE_APP_API_HOST}/setting/users/${this.form.user_id}`,
        this.bearerToken
      );
      this.confirm_form = false;
      this.getData();
      this.deleteDataModal();
    },
    reset_confirm() {
      this.confirm_form = false;
      this.form = {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        role: "",
        user_status: "",
      };
      this.showpassword = false;
    },
    async editDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async deleteDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async saveNewDataModal() {
      this.$swal({
        title: "Update Successfully!",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<style>
#mytable .v-data-table__wrapper table thead {
  background: rgb(220, 220, 220) !important;
}
</style>
