import Vue from "vue"
import VueI18n from "vue-i18n"
Vue.use(VueI18n)

const messages = {
    en: {
        sys: {
            lang_change: "th",
            temp: "temperature",
            humid: "humidity",
            moist: "soil moisture",
        },
        menu: {
            farm: localStorage.company_name,
            dashboard: "Dashboard",
            calendar: "Calendar",
            setting: "Settings",
            setting_farm: "Farm",
            setting_device: "IoT Device",
            setting_plant: "Plant",
            setting_user: "User",
            report: "Report",
            report_glasshouse_history: "Glasshouse History",
            report_glasshouse_income: "Glasshouse income",
        },
        farm: {
            title: localStorage.company_name,
        },
        dashboard: {
            title: "Dashboard",
        },
        settings: {
            title: "Settings",
        },
        reports: {
            title: "Reports",
        },
    },
    th: {
        sys: {
            lang_change: "en",
            temp: "อุณหภูมิ",
            humid: "ความชื้นในอากาศ",
            moist: "ความชื้นในดิน",
        },
        menu: {
            farm: localStorage.company_name,
            dashboard: "Dashboard",
            calendar: "ปฎิทิน",
            setting: "การตั้งค่า",
            setting_farm: "ฟาร์ม/โรงเรือน",
            setting_device: "อุปกรณ์ IoT",
            setting_plant: "พืช",
            setting_user: "ผู้ใช้งาน",
            report: "รายงาน",
            report_glasshouse_history: "ประวัติการปลูกพืช",
            report_glasshouse_income: "รายได้"
        },
        farm: {
            title: localStorage.company_name,
        },
        dashboard: {
            title: "Dashboard",
        },
        settings: {
            title: "การตั้งค่า",
        },
        reports: {
            title: "รายงาน",
        },
    },
}

const i18n = new VueI18n({
    locale: "th",
    messages: messages,
})

export default i18n
